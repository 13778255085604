import React, { useEffect } from "react"

// Utilities
import { scrollTo } from "../utilities/scrollTo"
import testimonials from "../utilities/testimonials"
import lazyLoad from "../utilities/handleLazyLoad"

// Components
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const ScrollToTabs = lazyLoad(() => import("../components/Scrollable/ScrollToTabs"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))
const Video = lazyLoad(() => import("../components/Video/Video"))

/**
 * @param tabs - tab content
 * @param product - shopify product
 * */
const HotAbsAndBooty = ({ tabs, product }) => {
  useEffect(() => {
    testimonials(product)
  }, [])

  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="30-Day Booty + Abs Challenge | The Betty Rocker"
        title="30-Day Booty + Abs Challenge"
        description="Define your abs and sculpt your booty and legs with this time-saving 30-day workout plan that can be done at home or in the gym!"
        url="https://store.thebettyrocker.com/products/30-day-booty-abs-challenge/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/booty-and-abs-fb.jpg"
      />

      {/*sales hero*/}
      <section className="hero pt--xs-30 pb--xs-30 bg--hero-abs">
        <div className="bg--hero-abs-mob txt--center">
          <h1 className="h1--md mb--40">
            30-Day <span className="txt--purple-dark">Booty + Abs</span> Challenge
          </h1>
        </div>

        <div className="container txt--center">
          <div className="container container--md">
            <div className="row mb--10 mb--xs-0">
              <div className="col-12 col-lg-9 offset-lg-3 col-xl-8 offset-xl-4">
                <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--10">
                  <Video url="https://player.vimeo.com/video/244466173" />
                </div>

                <h2 className="h2--md txt--uppercase">
                  leaner, tighter, <span className="d-inline-block">faster, stronger:</span>
                </h2>
                <p className="fw--black txt--purple-dark txt--25 txt--xs-18">
                  Sculpt, Strengthen and Define Your Abs, <span className="d-inline-block">Booty, and Legs!</span>
                </p>

                <a className="btn btn--md fw--medium" onClick={scrollTo} href="#start-now">
                  start now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTabs tabs={tabs} supplements={false} />

      <section className="container arrow-down js-section pt--xs-20" id="how-it-works">
        <h2 className="h2--sm txt--center mb--30 mb--xs-20">
          Here's How <span className="txt--purple-dark">It Works:</span>
        </h2>
        <h4 className="txt--center mb--40 mb--xs-30">
          Define your abs and sculpt your booty and legs with this time-saving <br className="d-none d-md-block" />
          30-day workout plan that can be done at home or in the gym!
        </h4>

        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--md-30">
            <div className="txt--center-md">
              <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                <img
                  loading="lazy"
                  src="/images/hot-abs/betty-1.jpg"
                  alt=""
                  className="no-shadow lazy"
                  width="332"
                  height="332"
                />
              </div>
            </div>
            <h3 className="txt--md-left txt--purple-dark mb--20 ml--50 ml--md-0 h3--mob mb--xs-15">
              <strong>TONE</strong>
            </h3>
            <ul className="checkmark-purple list--padding">
              <li>
                This program delivers proven, <strong>balanced and fun resistance training workouts</strong> that sculpt
                out the shape in your booty, legs and abs using minimal equipment.
              </li>
              <li>
                <strong>Hot Abs and Booty</strong> can be done at home with minimal equipment or in the gym – and in the
                demo videos you’ll see options for using a variety of equipment and in many cases, just your own
                bodyweight.
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--md-30">
            <div className="txt--center-md">
              <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                <img
                  loading="lazy"
                  src="/images/hot-abs/betty-2.jpg"
                  alt=""
                  className="no-shadow lazy"
                  width="332"
                  height="332"
                />
              </div>
            </div>
            <h3 className="txt--md-left txt--purple-dark mb--20 ml--50 ml--md-0 h3--mob mb--xs-15">
              <strong>SCULPT</strong>
            </h3>
            <ul className="checkmark-purple list--padding">
              <li>
                <strong>Do it from home or in the gym</strong> and see the fastest definition targeting and
                strengthening your abs and glutes from multiple angles so you not only get a beautiful sculpted shape,
                but improve your body’s function as well.
              </li>
              <li>
                <strong>Lift and shape your booty and define</strong> and shred your abs with this body shaping program!
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--md-30 mb--xs-0">
            <div className="txt--center-md">
              <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                <img
                  loading="lazy"
                  src="/images/hot-abs/betty-3.jpg"
                  alt=""
                  className="no-shadow lazy"
                  width="332"
                  height="332"
                />
              </div>
            </div>
            <h3 className="txt--md-left txt--purple-dark mb--20 ml--50 ml--md-0 h3--mob mb--xs-15">
              <strong>DEFINE</strong>
            </h3>
            <ul className="checkmark-purple list--padding">
              <li>
                You’ll get a <strong>30 day plan that includes upper body and full body training</strong> as well for a
                balanced workout program with a specific focus on the butt, legs and abs!
              </li>
              <li>
                This program is primarily geared toward intermediate exercisers, but is{" "}
                <strong>appropriate for beginners as well</strong>, as you’ll have demonstrations and modifications.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section className="arrow-down bg--gray-top js-section" id="results">
        <div className="container">
          <h2 className="h2--sm mb--40 mb--xs-30 lh--xs-1_3">
            Just A Few <span className="txt--purple-dark">Booty+Abs Program</span> Results:
          </h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hot-abs/testimonial-1.jpg"
                    alt="Testimonial of Laura M."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I feel stronger, sexier and lighter!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I’m REALLY pleased with the changes my body is making! I feel stronger, sexier, lighter, and
                      generally better inside and out.
                    </p>
                    <p className="txt--pink">
                      <strong>- Laura M.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hot-abs/testimonial-2.jpg?v=1.0.1"
                    alt="Testimonial of Chantele V."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"So happy to see fat literally melting off"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I’m constantly looking at my midsection in the mirror because I’m so happy to see fat literally
                      melting off, and definition starting to form.
                    </p>
                    <p className="txt--pink">
                      <strong>- Chantele V.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-block d-sm-none d-md-block col-12 col-sm-6 col-md-4 mb--30 mb--xs-0">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hot-abs/testimonial-3.jpg?v=1.0.1"
                    alt="Testimonial of Kimmie S."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I could stick to this and feeling stronger!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      So here we are – a before and after shot! Still some ways to go but I”m proud that I could stick
                      to this and I’m feeling stronger!
                    </p>
                    <p className="txt--pink">
                      <strong>- Kimmie S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*what is included*/}
      <section className="bg--section arrow-down js-section" id="included">
        <h2 className="h2--sm mb--40 lh--xs-1_3 mb--xs-30">
          Your Complete <span className="txt--purple-dark">30-Day Hot Abs + Booty Workout Plan</span> Includes….
        </h2>
        <div className="container container--md">
          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--purple tag--sm">#1</div>
              <h4>
                <strong>
                  INCLUDED: Complete Legs + Booty Follow-Along <br className="d-none d-md-block" />
                  Video Workout Program <span className="txt--purple-dark">$127 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 mb--sm-30 txt--center">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/hot-abs/included-1.png"
                    alt=""
                    width="477"
                    height="348"
                  />
                </div>
                <div className="col-md-6">
                  <ul className="checkmark-purple">
                    <li>
                      <strong>
                        Follow along with me in the workout video so you can do the entire workout with me
                      </strong>{" "}
                      – with motivation, direction and form tips throughout.
                    </li>
                    <li>
                      Use the <strong>tutorial video for each workout</strong> to learn options for doing the moves in
                      the workouts with just your own bodyweight OR how to add simple equipment – dumbbells, bands, and
                      a swiss ball.
                    </li>
                    <li>
                      Keep your body guessing with <strong>fun, varied moves from workout to workout</strong> so you can
                      sculpt, tighten, lift and shred!
                    </li>
                    <li>
                      Get <strong>motivation and encouragement</strong> from the support of your coach while you’re
                      exercising in these fun videos!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--purple tag--sm">#2</div>
              <h4>
                <strong>
                  INCLUDED: Hot Abs Workouts Tutorial Videos <span className="txt--purple-dark">$47 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/hot-abs/included-2.png"
                    alt=""
                    width="467"
                    height="331"
                  />
                </div>
                <div className="col-md-6">
                  <ul className="checkmark-purple">
                    <li>
                      <strong>Sculpt your abs</strong> with these specially designed core workouts that firm, flatten
                      and define!
                    </li>
                    <li>
                      Learn the moves for each workout in the <strong>demo videos</strong>
                    </li>
                    <li>
                      <strong>Modifications and alternatives shown</strong> so you can build your strength using this
                      program
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--purple tag--sm">#3</div>
              <h4>
                <strong>
                  INCLUDED: Abs + Booty Workout Guide <span className="txt--purple-dark">$67 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/hot-abs/included-3.png"
                    alt=""
                    width="486"
                    height="351"
                  />
                </div>
                <div className="col-md-6">
                  <ul className="checkmark-purple">
                    <li>
                      In this companion guide to the Abs and Booty Challenge, you’ll get valuable information about how
                      to tailor this program to maximum effectiveness, whether you’re still in your{" "}
                      <strong>cycling years</strong>, in <strong>perimenopause</strong>, or{" "}
                      <strong>post menopause</strong>.
                    </li>
                    <li>
                      The guide will help you <strong>optimize</strong> your training and recovery days, fuel for
                      results, and master all the components that go into sculpting your strong, healthy body!
                    </li>
                    <li>
                      The guide includes <strong>written descriptions</strong> of all the workouts and form tips too, so
                      you can print it out and take it with you on the go!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--40 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--purple tag--sm">#4</div>
              <h4>
                <strong>
                  INCLUDED: 3 Workout Plan Tracks <span className="txt--purple-dark">$27 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/hot-abs/hot-abs-included-4.png"
                    alt=""
                    width="433"
                    height="330"
                  />
                </div>
                <div className="col-md-6">
                  <ul className="checkmark-purple">
                    <li>
                      3 Calendar track options optimized for <strong>women in different life stages</strong> and for
                      those with changing schedules.
                    </li>
                    <li>
                      In <strong>perimenopause</strong> or <strong>post menopause</strong>? Track 2 and 3 were created
                      with you in mind to help you get the best results with targeted training and strategic recovery!
                    </li>
                    <li>
                      You’ll speed to results with these custom formats at any age, whether you’re doing these workouts{" "}
                      <strong>at home or in the gym</strong>
                      and make amazing progress toward your goals!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="txt--center">
            <a className="btn btn--md fw--medium" onClick={scrollTo} href="#start-now">
              start now
            </a>
          </div>
        </div>
      </section>

      {/*meet your coach*/}
      <section className="bg--meet-coach js-section" id="meet-your-coach">
        {/*if changing heading here, change it on both locations desktop and responsive*/}
        <div className="bg--meet-coach-mob mb--20 d-flex align-items-center justify-content-center d-lg-none">
          <h2 className="h2--sm">
            Meet Your <span className="txt--purple-dark">Coach!</span>
          </h2>
        </div>
        <div className="container container--md">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-4">
              <h2 className="d-none d-lg-block h2--sm mb--30 txt--left">
                Meet Your <span className="txt--purple-dark">Coach!</span>
              </h2>

              <p className="fw--black">
                <span className="txt--purple-dark">Who is Betty Rocker?</span> Betty Rocker is me! My name is Bree
                Argetsinger, but I was called “punk rock Betty Crocker” since I was a little girl - which morphed into
                Betty Rocker! I’m a certified trainer, nutrition specialist and motivator of personal growth, and I
                support women all over the world of all ages as they transform inside and out.
              </p>
              <p>
                My passion for fitness and health developed after treating my body very badly for many years and
                choosing to educate myself and do the work to develop good practices based on science, my own intuition
                and a deep love and compassion for other people who were struggling as well.
              </p>
              <p>
                I have mastered many aspects of health and personal development, including becoming a C.H.E.K.
                (Corrective Exercise and High-performance Kinesiology) certified Exercise Coach, an I.S.S.A. certified
                nutrition specialist, and a Nationally Certified Structural Integration Practitioner (realignment of the
                body through manual therapy). I have pursued continuing education to incorporate training with your
                cycle, training in perimenopause and training post menopause in my programs.
              </p>
              <p>
                My goal is to empower you with the skills and knowledge you need to make the best choices for yourself
                inside and out – so you can love the person you are, achieve the results you’re looking for, and enjoy
                yourself along the way.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section className="arrow-down">
        <div className="container">
          <h2 className="h2--sm mb--40 mb--xs-20 lh--xs-1_3">
            More <span className="txt--purple-dark">Booty+Abs Program</span> Results:
          </h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hot-abs/testimonial-4.jpg"
                    alt="Testimonial of Nelly C."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I can see my abs!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>I can see my abs!</p>
                    <p className="txt--pink">
                      <strong>- Nelly C.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hot-abs/testimonial-5.jpg"
                    alt="Testimonial of Carol B."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"My progress from your program!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I just wanted to share my progress from your Abs + Booty Program and tabata workouts! I am a 33
                      year old mom of 2.9 months post-partum and 2 C-sections! I absolutely love your workouts and your
                      encouragement during the videos!!
                    </p>
                    <p className="txt--pink">
                      <strong>- Carol B.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-block d-sm-none d-md-block col-12 col-sm-6 col-md-4 mb--30 mb--xs-0">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/hot-abs/testimonial-6.jpg"
                    alt="Testimonial of Sabrina S."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"So excited about how much my body is changing!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>I am so excited about how much my body is changing!</p>
                    <p className="txt--pink">
                      <strong>- Sabrina S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*start now*/}
      <section className="bg--gray-top arrow-down" id="start-now">
        <div className="container pt--40 pt--xs-0">
          <h2 className="mb--10">START TODAY!</h2>

          <img
            loading="lazy"
            src="/images/hot-abs/abs-booty-full.png"
            alt=""
            width="1170"
            height="658"
            className="lazy no-shadow"
          />

          <div className="container container--md txt--center border-top-1 border-light-gray pt--30">
            <h2 className="fw--regular h2--xs h2--xs-20 h2--xxxxs-18 mb--40 mb--xs-20 lh--xs-1_3">
              30-Day Booty <span className="d-inline-block"> + Abs Challenge Plan </span>{" "}
              <span className="d-inline-block txt--purple-dark">
                <strong>($275 Value)</strong>
              </span>
            </h2>

            <DigitalProductBuyBox shopifyProduct={product} singleBox={true} color="purple-dark" />
          </div>
        </div>
      </section>

      {/*fb testimonials*/}
      <section className="bg--section arrow-down">
        <div className="container">
          <h2 className="h2--sm mb--30 mb--xs-20 lh--xs-1_3">
            More Success Stories <span className="d-inline-block txt--purple-dark">From Our Challengers!</span>
          </h2>
          <div className="row align-items-center img--stack-wrapper">
            <div className="col-md-6 mb--50 mb--xs-30">
              <div className="img-stack mb--50 mb--xs-30">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/hot-abs/fb-testimonial-1.jpg"
                  alt=""
                  width="544"
                  height="251"
                />
              </div>

              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/hot-abs/fb-testimonial-3.jpg"
                  alt=""
                  width="527"
                  height="96"
                />
              </div>
            </div>
            <div className="col-md-6 mb--50 mb--xs-20">
              <div className="img-stack mb--50 mb--xs-30">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/hot-abs/fb-testimonial-2.jpg"
                  alt=""
                  width="542"
                  height="222"
                />
              </div>

              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/hot-abs/fb-testimonial-4.jpg"
                  alt=""
                  width="540"
                  height="190"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*guarantee*/}
      <div className="container pt--30 pb--50 pb--xs-30 pt--sm-120">
        <div className="box-guarantee box-guarantee--purple">
          <img
            loading="lazy"
            src="/images/guarantee-100.png"
            alt=""
            width="254"
            height="261"
            className="lazy no-shadow"
          />
          <div className="row">
            <div className="col-lg-8">
              <h2 className="h2--sm txt--white mb--20 txt--smd-left">100% Money Back GUARANTEE</h2>

              <p className="txt--white lh--auto">
                Take the first step toward improving your health by trying my 30-day Booty and Abs challenge plan for
                the next 30 days. If you don’t feel healthier, more energized and simply amazing, you won’t pay. If
                you’re not satisfied, simply contact customer service within 30 days for a full refund.
              </p>

              <p className="txt--white txt--22 fw--black">
                <span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="bg--section js-section py--50 pb--xs-30 pt--xs-30" id="faq">
        <div className="container">
          <h2 className="mb--40 h2--mob mb--xs-20">
            Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span>
          </h2>
        </div>
        <div className="container container--xs">
          <Faqs color="purple" productName="hot-abs" />
        </div>
      </section>
    </>
  )
}
export default HotAbsAndBooty
