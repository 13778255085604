import React, { useEffect, useState } from "react"

// Utilities
import { scrollTo } from "../utilities/scrollTo"
import testimonials from "../utilities/testimonials"
import lazyLoad from "../utilities/handleLazyLoad"

// Components
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const ScrollToTabs = lazyLoad(() => import("../components/Scrollable/ScrollToTabs"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))
const Video = lazyLoad(() => import("../components/Video/Video"))

/**
 * @param tabs - tab content
 * @param product - shopify product data
 * */

const Lioness = ({ tabs, product }) => {
  /*==========================
    READ MORE
    =========================*/
  const [readMore, setReadMore] = useState(false)

  useEffect(() => {
    testimonials(product)
  }, [])
  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="Lioness - 8-Week Women’s Strength Training Program | The Betty Rocker"
        title="Lioness - 8-Week Women’s Strength Training Program"
        description="Build, strengthen and sculpt your most fit, sculpted physique ever with the empowering 8-week women's strength training program!"
        url="https://store.thebettyrocker.com/products/lioness/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/lioness-fb.jpg"
      />

      {/*sales hero*/}
      <section className="hero bg--section pt--20 bg--hero-lioness">
        {/*if updated title change both desktop and responsive versions*/}
        <div className="bg--hero-lioness-mob d-flex flex-column justify-content-center align-items-start d-lg-none">
          <h1 className=" txt--100 txt--left txt--uppercase mb--5">Lioness</h1>
          <h2 className="txt--left txt--36 mb--20">
            <span className="txt--blue">8-Week Women’s</span> Strength Training Program
          </h2>
        </div>

        <div className="container">
          <div className="container container--md">
            <div className="row mb--10 mb--xs-0">
              <div className="col-12 col-lg-9 offset-lg-3 col-xl-8 offset-xl-4">
                <h1 className="d-none d-lg-block txt--100  txt--left txt--uppercase mb--5">Lioness</h1>
                <h2 className="d-none d-lg-block txt--left txt--36 mb--20">
                  <span className="txt--blue">8-Week Women’s</span> Strength Training Program
                </h2>
                <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--20">
                  <Video url="https://player.vimeo.com/video/264865723" />
                </div>

                <div className="txt--center">
                  <a className="btn btn--md fw--medium" onClick={scrollTo} href="#start-now">
                    start now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTabs tabs={tabs} supplements={false} />

      <section className="container arrow-down js-section pt--xs-20" id="how-it-works">
        <h2 className="h2--sm txt--center mb--30 mb--xs-10">
          Here's How <span className="txt--blue">It Works:</span>
        </h2>
        <h4 className="txt--center mb--40 mb--xs-30">
          Unleash your power with progressive strength workouts designed to <br className="d-none d-md-block" /> sculpt
          lean muscle and build a fit physique!
        </h4>

        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 item-md-border">
            <div className="txt--center-md">
              <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                <img
                  loading="lazy"
                  src="/images/lioness/lioness-1.png"
                  alt=""
                  className="no-shadow lazy"
                  width="332"
                  height="332"
                />
              </div>
            </div>
            <h3 className="txt--md-left txt--blue mb--20 ml--50 ml--md-0 txt--uppercase h3--mob mb--xs-10">
              <strong>Strengthen</strong>
            </h3>
            <ul className="checkmark-blue list--padding">
              <li>
                Build lean muscle with specially sequenced workouts using progressive overload to define sculpted legs,
                a lifted booty, a strong back and arms, and flat abs.
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 item-md-border">
            <div className="txt--center-md">
              <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                <img
                  loading="lazy"
                  src="/images/lioness/lioness-2.png"
                  alt=""
                  className="no-shadow lazy"
                  width="332"
                  height="332"
                />
              </div>
            </div>
            <h3 className="txt--md-left txt--blue mb--20 ml--50 ml--md-0 txt--uppercase h3--mob mb--xs-10">
              <strong>Sculpt</strong>
            </h3>
            <ul className="checkmark-blue list--padding">
              <li>
                No bulk, just sculpt! Watch body fat drop as you fuel with the right nutrients and do the right workouts
                to create an optimal state in your system for body composition changes.
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-8 offset-sm-2 col-lg-4 offset-lg-0 mb--sm-30 mb--xs-0">
            <div className="txt--center-md">
              <div className="d-inline-block txt--center border-10 border-white shadow-3 mb--30">
                <img
                  loading="lazy"
                  src="/images/lioness/lioness-3.png"
                  alt=""
                  className="no-shadow lazy"
                  width="332"
                  height="332"
                />
              </div>
            </div>
            <h3 className="txt--md-left txt--blue mb--20 ml--50 ml--md-0 txt--uppercase h3--mob mb--xs-10">
              <strong>Shred</strong>
            </h3>
            <ul className="checkmark-blue list--padding">
              <li>
                Enjoy the versatility of training from home or in the gym with both full follow along video guides, and
                short video tutorials so you can move around with ease on your own time frame.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section className="arrow-down bg--section js-section" id="results">
        <div className="container">
          <h2 className="h2--sm mb--40 lh--xs-1_3 mb--xs-30">
            Betty Rocker <span className="d-inline-block txt--blue">Program Results:</span>
          </h2>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/lioness/testimonial-1.jpg?v=1.0.1"
                    alt="Testimonial of Julie."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Love my results!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I’m so ecstatic for the results I’ve finally been able to achieve! After years of trying to get my
                      body back, the Lioness Program gave me the structure, motivation, and ease of use I’ve been
                      searching for. I love that I didn’t have to go to the gym every day and I still saw results like
                      I’ve never seen before. Having the program on my phone was like having my own personal trainer
                      with me at all times. I’m now in maintenance mode but I re-do the program about once a year to
                      give me a pick-me-up whenever I’m feeling uninspired. It’s my #1 fitness recommendation to my
                      clients. Thank you Bree for my body freedom!
                    </p>
                    <p className="txt--pink">
                      <strong>- Julie</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/lioness/testimonial-2.jpg?v=1.0.1"
                    alt="Testimonial of Amy P."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I’ve lost 20 pounds!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>I just want to share my progress pics. I’ve lost 20 pounds! #muscles</p>
                    <p className="txt--pink">
                      <strong>- Amy P.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 d-block d-sm-none d-md-block mb--30 mb--xs-0">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/lioness/testimonial-3.jpg"
                    alt="Testimonial of Betty Rocker."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I loved writing this plan and use it all the time!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I use Lioness whenever I want to quickly add lean muscle to my body and get an amazing result. I
                      loved writing this plan and use it all the time!
                    </p>
                    <p className="txt--pink">
                      <strong>- Betty Rocker</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--lioness-dark js-section" id="included">
        <div className="container">
          <h2 className="txt--white h2--sm mb--60 mt--10 mb--xs-30 lh--xs-1_3">
            Your Complete <span className="txt--blue">Lioness Training Program</span> Includes…
          </h2>

          <div className="row align-items-center row--border">
            <div className="col-12 col-lg-6 txt--center mb--10">
              <img
                loading="lazy"
                src="/images/lioness/lioness-complete-60day.png"
                alt="Complete 60-day Workout."
                width="610"
                height="551"
                className="lazy no-shadow"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
              <h4 className="txt--uppercase txt--left mb--20 txt--white txt--uppercase">
                <strong>
                  Complete 60-Day Workout Program <span className="txt--blue d-inline-block">$249 VALUE</span>
                </strong>
              </h4>
              <ul className="checkmark-blue pb--20 pb--xs-0">
                <li className="txt--white">
                  Full follow along workout videos so you can learn every move with me to guide you, great to use in
                  your home gym
                </li>
                <li className="txt--white">Short demo videos perfect for quick reference if you’re going to the gym</li>
                <li className="txt--white">
                  Options to use simple home gym equipment like dumbbells and stretchy bands if you are new or want to
                  do this from home
                </li>
                <li className="txt--white">
                  Options to use heavier equipment like barbells and a pull up bar, an exercise ball and bands/cables
                  and progress your strength as you go
                </li>
                <li className="txt--white">Options for beginner, intermediate and advanced</li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center row--border">
            <div className="col-12 col-lg-6 txt--center mb--10">
              <img
                loading="lazy"
                src="/images/lioness/included-3.png"
                alt=""
                width="529"
                height="254"
                className="lazy no-shadow"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
              <h4 className="txt--uppercase txt--center-md mb--20 txt--white txt--uppercase">
                <strong>
                  INCLUDED: Workout Warmups <span className="d-inline-block txt--blue">$79 VALUE</span>
                </strong>
              </h4>
              <ul className="checkmark-blue">
                <li className="txt--white">
                  Prevent Injuries and Make Your Workouts More Effective with a Warmed-Up Body
                </li>
                <li className="txt--white">Follow Along Video Demos for Your Warmups, no equipment needed</li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center row--border">
            <div className="col-12 col-lg-6 txt--center mb--md-20">
              <img
                loading="lazy"
                src="/images/lioness/included-4.png"
                alt=""
                width="529"
                height="254"
                className="lazy no-shadow"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
              <h4 className="txt--uppercase txt--center-md mb--20 txt--white txt--uppercase">
                <strong>
                  INCLUDED: Ab Workouts <span className="d-inline-block txt--blue">$129 VALUE</span>
                </strong>
              </h4>
              <ul className="checkmark-blue">
                <li className="txt--white">Shred a Sexy Stomach with these Companion Ab Workouts</li>
                <li className="txt--white">
                  Done-for-you Sequencing so You Know When to Do Your Ab Workouts for Maximum Effect
                </li>
                <li className="txt--white">
                  Video Demos for Every Move, with Plenty of Variations for All Fitness Levels
                </li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center row--border">
            <div className="col-12 col-lg-6 txt--center mb--md-20">
              <img
                loading="lazy"
                src="/images/lioness/workout-finisher.png"
                alt=""
                width="529"
                height="254"
                className="lazy no-shadow"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white">
              <h4 className="txt--uppercase txt--center-md mb--20 txt--white txt--uppercase">
                <strong>
                  Workout Finishers <span className="d-inline-block txt--blue">$99 VALUE</span>
                </strong>
              </h4>
              <ul className="checkmark-blue">
                <li className="txt--white">
                  Blast Fat with these 10-Minute HIIT Circuits and take your Results to the Next Level
                </li>
                <li className="txt--white">Follow-Along Video Demos</li>
                <li className="txt--white">No Equipment Needed – Do these Anywhere!</li>
              </ul>
            </div>
          </div>

          <div className="row align-items-center mb--20">
            <div className="col-12 col-lg-6 txt--center mb--10">
              <img
                loading="lazy"
                src="/images/lioness/included-5-new.png"
                alt=""
                width="529"
                height="455"
                className="lazy no-shadow"
              />
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 txt--white py--20">
              <h4 className="txt--uppercase txt--center-md mb--20 txt--white">
                <strong>
                  INCLUDED: 8-Week Done-for-You Meal Plan <span className="d-inline-block txt--blue">$147 VALUE</span>
                </strong>
              </h4>
              <ul className="checkmark-blue">
                <li className="txt--white">Complete 8-Week Meal Plan</li>
                <li className="txt--white">
                  Family-Friendly Whole Food Recipes -Gluten Free, Dairy Free, plus Vegetarian Options Included
                </li>
                <li className="txt--white">Grocery Shop with Ease Using Your Weekly Shopping List</li>
                <li className="txt--white">
                  Easily Prep Some or All of Your Weekly Food with Done for You Food Prep Steps
                </li>
                <li className="txt--white">Eliminate all the Guesswork with Daily Menus, with room for Eating out</li>
                <li className="txt--white">
                  Complete Getting Started Guide so You can Get Maximum Benefit and Optimal Results
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/*bonuses*/}
      <section className="arrow-down container container--md">
        <h2 className="h2--sm mb--40 mb--xs-30 lh--xs-1_3">
          <span className="txt--blue">Special Bonuses</span> - To BOOST Your Results
        </h2>
        <div className="card card--tag-sm mb--30 mb--xs-20">
          <div className="card__head card__head--gray">
            <div className="tag tag--blue">
              <span className="d-none d-lg-inline-block">Bonus</span> #1
            </div>
            <h4 className="txt--uppercase">
              <strong>
                The Complete Lioness Program Guide <span className="txt--blue d-inline-block">$77 VALUE</span>
              </strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center">
              <div className="col-md-6 mb--sm-30 txt--center">
                <img className="no-shadow lazy" src="/images/lioness/bonus-1.png" alt="" width="450" height="351" />
              </div>
              <div className="col-md-6">
                <ul className="checkmark-blue">
                  <li>Getting stronger means muscle</li>
                  <li>How muscle impacts your body composition</li>
                  <li>How muscle is gained and lost through exercise</li>
                  <li>Hormones and factors that play a key role in muscle growth</li>
                  <li>Strong body foundations</li>
                  <li>Phase 1 and 2 program guidelines</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--tag-sm mb--30 mb--xs-20">
          <div className="card__head card__head--gray">
            <div className="tag tag--blue">
              <span className="d-none d-lg-inline-block">Bonus</span> #2
            </div>
            <h4 className="txt--uppercase">
              <strong>
                Custom Lioness Workout Trackers <span className="txt--blue d-inline-block">$57 VALUE</span>
              </strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row align-items-center">
              <div className="col-md-6 txt--center mb--sm-30">
                <img className="no-shadow lazy" src="/images/lioness/bonus-2.png" alt="" width="450" height="351" />
              </div>
              <div className="col-md-6">
                <ul className="checkmark-blue">
                  <li>Printable workout tracker to take with you on the go</li>
                  <li>Conveniently track your progress as you workout to see how your strength increases</li>
                  <li>Motivate yourself by looking back and see how far you’ve come!</li>
                  <li>Reuse as many times as you like!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card card--tag-sm mb--30 mb--xs-0">
          <div className="card__head card__head--gray">
            <div className="tag tag--blue">
              <span className="d-none d-lg-inline-block">Bonus</span> #3
            </div>
            <h4 className="txt--uppercase">
              <strong>
                60-Day E Coaching Course <span className="txt--blue d-inline-block">$67 VALUE</span>
              </strong>
            </h4>
          </div>
          <div className="card__content">
            <div className="row">
              <div className="col-md-6 txt--center mb--sm-30">
                <img className="no-shadow lazy" src="/images/lioness/bonus-3.png" alt="" width="450" height="288" />
              </div>
              <div className="col-md-6">
                <ul className="checkmark-blue">
                  <li>Personal emails from your coach (me) to encourage and support you on your fitness journey!</li>
                  <li>
                    Motivating content each week covering nutrition, fitness and mindset to help you stay on track with
                    your plan
                  </li>
                  <li>Be supported and inspired as you work your way to great results</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*meet your coach*/}
      <section className="arrow-down bg--lioness-coach js-section" id="meet-your-coach">
        {/*    if changing heading here, change it on both locations desktop and responsive*/}
        <div className="mb--30 mb--xs-0 d-flex justify-content-center d-lg-none">
          <h2 className="txt--white txt--center">
            Meet Your <span className="txt--blue">Coach!</span>
          </h2>
        </div>
        <div className="container txt--white pt--30">
          <div className="row">
            <div className="col-12 col-md-4 txt--center">
              <img
                src="/images/lioness/lioness-betty-coach.png"
                alt=""
                width="370"
                height="370"
                className="mb--40 lazy no-shadow"
              />
            </div>
            <div className="col-12 col-md-8">
              <h2 className="d-none d-lg-block h2--sm mb--30 txt--left txt--white">
                Meet Your <span className="txt--blue">Coach!</span>
              </h2>

              <p className="txt--white">
                <strong className="fw--bold">
                  <span className="txt--blue">Who is Betty Rocker?</span> Betty Rocker is me! My name is Bree
                  Argetsinger, but I was called “punk rock Betty Crocker” since I was a little girl - which morphed into
                  Betty Rocker! I’m a certified trainer, nutrition specialist and motivator of personal growth, and I
                  support women all over the world of all ages as they transform inside and out.
                </strong>
              </p>
              <p className="txt--white">
                My passion for fitness and health developed after treating my body very badly for many years and
                choosing to educate myself and do the work to develop good practices based on science, my own intuition
                and a deep love and compassion for other people who were struggling as well.
              </p>

              {readMore && (
                <div className="mb--30">
                  <p className="txt--white clearfix">
                    I have mastered many aspects of health and personal development, including becoming a C.H.E.K.
                    (Corrective Exercise and High-performance Kinesiology) certified Exercise Coach, an I.S.S.A.
                    certified nutrition specialist, and a Nationally Certified Structural Integration Practitioner
                    (realignment of the body through manual therapy). I have pursued continuing education to incorporate
                    training with your cycle, training in perimenopause and training post menopause in my programs.
                  </p>
                  <p className="txt--white">
                    My goal is to empower you with the skills and knowledge you need to make the best choices for
                    yourself inside and out – so you can love the person you are, achieve the results you’re looking
                    for, and enjoy yourself along the way.
                  </p>
                </div>
              )}
              <button
                className={`link link--blue link--read-more ${readMore ? "active" : ""}`}
                onClick={() => setReadMore(!readMore)}
              >
                Read {readMore ? "Less" : "More"}
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--lioness-ready">
        <div className="container">
          <div className="row py--20 pt--xs-0">
            <div className="col-12 col-lg-6 offset-lg-6">
              <h2 className="h2--sm txt--md-left txt--md-blue mb--20">Ready, Set, Shred!</h2>
              <p>
                Lioness Strength Training will give you so much more than a stronger body. It will reveal a whole new
                level of YOU.
              </p>
              <p>The you who celebrates her inner badass. The you walks confidently into every room.</p>
              <p>Because you have ALWAYS been beautiful.</p>
              <p>Now you’re just deciding to be HEALTHIER, FITTER, FASTER, and STRONGER.</p>
              <p>SELF CONFIDENCE is the best outfit. ROCK IT. OWN IT. BE IT.</p>
            </div>
          </div>
          <div className="txt--center d-block d-lg-none">
            <img
              src="/images/lioness/lioness-ready-mob.jpg"
              width="600"
              height="400"
              alt=""
              className="no-shadow lazy"
            />
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section className="arrow-down">
        <div className="container">
          <h2 className="h2--sm mb--40 mb--xs-30 lh--xs-1_3">
            Betty Rocker <span className="d-inline-block txt--blue">Program Results:</span>
          </h2>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    className="lazy"
                    src="/images/lioness/testimonial-4.jpg"
                    alt="Testimonial of Linda B."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Working on my 6-pack"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>Thanks Betty Rocker! I won’t give up – still going strong – working on my 6-pack!</p>
                    <p className="txt--pink">
                      <strong>- Linda B.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    className="lazy"
                    src="/images/lioness/testimonial-5.jpg"
                    alt="Testimonial of Nola B."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Phase 1 done!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      Lioness Phase 1 complete! Where have the last 4 weeks gone? I’m really enjoying this program. I’ve
                      lost an inch off my waist and gained muscle :)
                    </p>
                    <p className="txt--pink">
                      <strong>- Nola B.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 d-block d-sm-none d-md-block mb--30 mb--xs-0">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    className="lazy"
                    src="/images/lioness/testimonial-6.jpg"
                    alt="Testimonial of Jo S."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Look! It’s working!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I really didn’t think changing what I ate and doing a little bit of exercise each day would make a
                      difference. But LOOK!! It’s working!
                    </p>
                    <p className="txt--pink">
                      <strong>- Jo S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*start now*/}
      <section className="bg--section arrow-down" id="start-now">
        <div className="container pt--40 pt--xs-0">
          <h2 className="mb--10 h2--mob">BECOME A LIONESS TODAY!</h2>

          <div className="txt--center">
            <img
              src="/images/lioness/main-lioness-new.png"
              alt=""
              width="1069"
              height="581"
              className="lazy no-shadow mb--20"
            />
          </div>

          <div className="container container--md txt--center border-top-1 border-light-gray pt--30">
            <h2 className="fw--regular h2--xs h2--xs-20 h2--xxxxs-18 mb--40 mb--xs-20 lh--xs-1_3">
              <strong>Lioness Program</strong> <span className="txt--blue">($502 Value)</span>{" "}
              <strong>+ Bonuses </strong> <span className="txt--blue">($201 Value)</span>{" "}
              <span className="d-inline-block">
                <strong>= $703 Total Value</strong>
              </span>
            </h2>

            <DigitalProductBuyBox shopifyProduct={product} color="blue" />
          </div>
        </div>
      </section>

      {/*fb testimonials*/}
      <section className="container pb--0">
        <h2 className="h2--sm mb--30 lh--xs-1_3">
          Betty Rocker <span className="d-inline-block txt--blue">Program Results</span>
        </h2>
        <div className="row align-items-center img--stack-wrapper">
          <div className="col-md-6 mb--50 mb--xs-30">
            <div className="img-stack mb--50 mb--xs-30">
              <img className="lazy" src="/images/lioness/fb-testimonial-1.jpg" alt="" width="544" height="142" />
            </div>

            <div className="img-stack mb--50 mb--xs-30">
              <img className="lazy" src="/images/lioness/fb-testimonial-3.jpg" alt="" width="548" height="253" />
            </div>

            <div className="img-stack">
              <img className="lazy" src="/images/lioness/fb-testimonial-5.jpg" alt="" width="537" height="135" />
            </div>
          </div>
          <div className="col-md-6 mb--50 mb--xs-0">
            <div className="img-stack mb--50 mb--xs-30">
              <img className="lazy" src="/images/lioness/fb-testimonial-2.jpg" alt="" width="547" height="238" />
            </div>

            <div className="img-stack mb--50 mb--xs-10">
              <img className="lazy" src="/images/lioness/fb-testimonial-4.jpg" alt="" width="547" height="307" />
            </div>
          </div>
        </div>
      </section>

      {/*guarantee*/}
      <div className="container pt--30 pb--50 pb--xs-30">
        <div className="box-guarantee box-guarantee--blue">
          <img src="/images/guarantee.png" alt="" width="239" height="245" className="lazy no-shadow" />
          <div className="row">
            <div className="col-lg-8">
              <h2 className="h2--sm txt--white mb--20 txt--smd-left">60 – Day Money Back GUARANTEE</h2>

              <p className="txt--white lh--auto">
                Take the first step toward improving your health by trying my 8-week Lioness Strength Training Program.
                If you don’t feel healthier, more energized and simply amazing, you won’t pay. If you’re not satisfied,
                simply contact customer service within 60 days for a full refund.
              </p>

              <p className="txt--white txt--22 fw--black">
                <span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="bg--section js-section py--50 pb--xs-30 pt--xs-30" id="faq">
        <div className="container">
          <h2 className="mb--40 h2--mob mb--xs-20 lh--xs-1_3">
            Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span>
          </h2>
        </div>
        <div className="container container--xs">
          <Faqs color="blue" productName="lioness" />
        </div>
      </section>
    </>
  )
}
export default Lioness
