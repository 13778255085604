import React, { useEffect } from "react"

// Utilities
import { scrollTo } from "../utilities/scrollTo"
import testimonials from "../utilities/testimonials"
import lazyLoad from "../utilities/handleLazyLoad"

// Components
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const ScrollToTabs = lazyLoad(() => import("../components/Scrollable/ScrollToTabs"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))
const Video = lazyLoad(() => import("../components/Video/Video"))

/**
 * @param tabs - tab content
 * @param product - shopify product
 * */
const NinetyDayChallenge = ({ tabs, product }) => {
  useEffect(() => {
    testimonials(product)
  }, [])

  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="90 Day Home Workout Challenge | The Betty Rocker"
        title="90 Day Home Workout Challenge"
        description="Get stronger, leaner, faster and more energized from home in with new moves in each 15 minute workout to challenge your muscles and fast-track your fitness! Includes a meal plan!"
        url="https://store.thebettyrocker.com/products/90-day-challenge/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/90dch-fb.jpg"
      />

      {/*sales hero*/}
      <section className="hero bg--90day py--30 py--xs-20">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-sm-8 col-lg-9 col-xl-9">
              <div className="mb--25 pr--xs-20p">
                <h1 className="h1--sm txt--left">90 Day Home Workout Challenge</h1>
                <h2 className="h2--sm txt--pink txt--left mb--50 mb--xs-20">
                  <strong>
                    <span className="line-brush line-md-none">#makefatcry</span> in <br className="d-sm-none" />
                    15 minutes a Day!
                  </strong>
                </h2>
              </div>
            </div>
            <div className="col-sm-8 col-lg-8 col-xl-7">
              <div className="video bg--white video--16-9 border-5 border-white mb--15">
                <Video url="https://player.vimeo.com/video/422578783" />
              </div>
              <div className="txt--center">
                <p className="txt--26 txt--xs-23">
                  <span className="txt--pink">
                    No Equipment. <strong className="fw--bold">No Excuses</strong>
                  </span>
                  <strong className="fw--bold"> just #stopdropandbettyrock</strong>
                </p>
                <a className="btn btn--md mb--10 fw--medium mb--xs-0" onClick={scrollTo} href="#start-now">
                  start now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ScrollToTabs tabs={tabs} supplements={false} />

      <section className="bg--section arrow-down py--sm-30">
        <div className="container container--smd d-flex flex-column d-md-block">
          <h2 className="mb--40 txt--capitalize txt--pink mb--xs-20 h2--mob lh--xs-1_3">
            Get Fitter, Faster, Healthier And Stronger <br className="d-none d-lg-block" />
            From Home, In Just 15 Minutes A Day!
          </h2>

          <div className="row no-gutters mb--15 mb--sm-30 mb--xs-20">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 mb--25">
              <ul>
                <li>15 Minute Follow-Along Video Workouts</li>
                <li>No Equipment Needed</li>
                <li>Get Stronger, Healthier and Fitter from Home</li>
              </ul>
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0">
              <ul>
                <li>Constant Variety, Fun, Flexible and Effective!</li>
                <li>Options for Beginner, Intermediate and Advanced</li>
                <li>Rock Your Body….Anytime, Anywhere!</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="img--border">
            <img
              loading="lazy"
              src="/images/90dch/90dch-fitter.jpg"
              alt=""
              width="1170"
              height="468"
              className="lazy img--shadow"
            />
          </div>
        </div>
      </section>

      {/*fb testimonials*/}
      <section className="container arrow-down">
        <div className="row align-items-center mb--20 img--stack-wrapper">
          <div className="col-md-6 mb--50 mb--xs-30">
            <div className="img-stack">
              <img
                loading="lazy"
                className="lazy"
                src="/images/90dch/fb-testimonial-3.jpg"
                alt=""
                width="990"
                height="514"
              />
            </div>
          </div>
          <div className="col-md-6 mb--50 mb--xs-30">
            <div className="img-stack">
              <img
                loading="lazy"
                className="lazy"
                src="/images/90dch/fb-testimonial-4.jpg"
                alt=""
                width="503"
                height="208"
              />
            </div>
          </div>
          <div className="col-md-6 mb--50 mb--xs-30">
            <div className="img-stack">
              <img
                loading="lazy"
                className="lazy"
                src="/images/90dch/fb-testimonial-1.jpg"
                alt=""
                width="490"
                height="257"
              />
            </div>
          </div>
          <div className="col-md-6 mb--50 mb--xs-30">
            <div className="img-stack">
              <img
                loading="lazy"
                className="lazy"
                src="/images/90dch/fb-testimonial-2.jpg"
                alt=""
                width="943"
                height="471"
              />
            </div>
          </div>
        </div>
        <p className="txt--center txt--15 lh--auto">
          <em>
            * Individual results may vary and may not be typical or representative of what you might expect.{" "}
            <br className="d-none d-lg-block" />
            To learn more about our community’s experiences with our programs look at our Testimonial Support page.
          </em>
        </p>
      </section>

      {/*testimonials*/}
      <section id="results" className="bg--section js-section arrow-down">
        <div className="container container--md">
          <h2 className="mb--40 txt--capitalize txt--pink h2--mob mb--xs-20 lh--xs-1_3">
            These women just followed the plan!
          </h2>

          <div className="row">
            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-1.jpg"
                    alt="Testimonial of Courtney."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Rocked her body and life…</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “Thank you Betty Rocker! The 90 day challenge was a complete success! I’ve seen so many changes
                      since I started.
                    </p>
                    <p>
                      Since completing the program, we continue to follow your daily meal structure, and our daily meal
                      choices align with food in the 90 day challenge. Thank you so much! I have never in my adult life
                      ever had abs or arms like I have now!”
                    </p>
                    <p className="txt--pink">
                      <strong>- Cortney F.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-2.jpg?v=1.0.1"
                    alt="Testimonial of Jen Y."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Got totally shredded!</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “I finished the 90 day challenge today! I feel like such a badass! I looked at B.B. (before Betty)
                      and A.B. (after Betty) photos today, and I”m reminded how much I’ve accomplished so far.”
                    </p>
                    <p className="txt--pink">
                      <strong>- Jen Y.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-3.jpg"
                    alt="Testimonial of Dana C."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">
                      Fun, easy to fit into my days, and best of all I have seen results!
                    </h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “My goals were to lose cm’s, feel good in my skin and commit to regular exercise. I have achieved
                      all of it and feel great! I am stronger, I have more energy, I look forward to my workouts, and I
                      have enjoyed the challenge.”
                    </p>
                    <p className="txt--pink">
                      <strong>- Dana C.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-4.jpg"
                    alt="Testimonial of Jen W."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">First 30 Days Progress…</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “Here are my progress pics – slow and steady work! It’s exciting to see the results of my hard
                      work!”
                    </p>
                    <p className="txt--pink">
                      <strong>- Jen W.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-5.jpg?v=1.0.1"
                    alt="Testimonial of Lisa Y."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">The Workouts Were the Perfect Fit!</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “The only program I’ve been using is the Betty Rocker workouts. I started with the free 30 day,
                      then purchased the 90 day. I’m now halfway through cycling back through the 90 day again. The
                      Betty Rocker workouts have been perfect for me!”
                    </p>
                    <p className="txt--pink">
                      <strong>- Lisa Y.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--10 mb--xs-0">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-6.jpg"
                    alt="Testimonial of Dianna A."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Got Ready For Her Sister’s Wedding…</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “Last week I posted a pic of me in my bridesmaid dress which didn’t close up all the way and I
                      have to wear on 5/17 for my little sisters wedding. Fellow challengers look at my progress! It
                      zipped up yesterday for the first time. I’m soooo happy! Thanks to all of you for your support and
                      encouragement.”
                    </p>
                    <p className="txt--pink">
                      <strong>- Dianna A.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container container--md js-section" id="how-it-works">
        <h2 className="txt--pink txt--capitalize mb--5 h2--mob mb--xs-10">Here's how it works</h2>
        <h3 className="font-lato mb--40 txt--xs-20 mb--xs-0">
          A Simple Formula for Strengthening, Toning, and Making Fat Cry <br className="d-none d-md-block" /> that You
          Don’t Even Have to Leave Home to Do!
        </h3>

        <div className="row border-bottom-1 border-light-gray mb--40 pb--30 mb--xs-20">
          <div className="col-12 col-sm-4 txt--center mb--20">
            <img
              loading="lazy"
              className="lazy no-shadow"
              src="/images/90dch/progressive.png"
              alt=""
              width="218"
              height="290"
            />
          </div>
          <div className="col-12 col-sm-8">
            <h3 className="txt--pink mb--30 txt--uppercase txt--sm-left h3--mob mb--xs-10">
              <strong>Progressive</strong>
            </h3>
            <ul>
              <li>
                <strong>WATCH YOURSELF PROGRESS:</strong> Each week, the workouts get progressively more challenging.
                You’ll have the complete set up to adjust and modify as needed, and progress at your own pace.
              </li>
              <li>
                <strong>EMPOWER YOURSELF:</strong> Gain confidence as you take action using this program, and enjoy more
                energy, better sleep and more happiness as you follow the plan!
              </li>
            </ul>
          </div>
        </div>
        <div className="row border-bottom-1 border-light-gray mb--40 pb--30 mb--xs-20">
          <div className="col-12 col-sm-4 txt--center mb--20">
            <img
              loading="lazy"
              className="lazy no-shadow"
              src="/images/90dch/sandclock.png"
              alt=""
              width="205"
              height="296"
            />
          </div>
          <div className="col-12 col-sm-8">
            <h3 className="txt--pink mb--30 txt--uppercase txt--sm-left h3--mob mb--xs-10">
              <strong>Time saving</strong>
            </h3>
            <ul>
              <li>
                <strong>TONE AND STRENGTHEN IN JUST 15-20 MIN:</strong> Each week, you’ll have a series of metabolic
                conditioning workouts to do to work your body from head to toe! These fast, fun, effective follow-along
                workouts will save you time!
              </li>
              <li>
                <strong>NO EQUIPMENT NEEDED:</strong> Do this from home or take it with you on the go – you can do these
                workouts anytime, anywhere and it makes a great resource for traveling!
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-4 txt--center mb--20 pb--30 mb--xs-0">
            <img
              loading="lazy"
              className="lazy no-shadow"
              src="/images/90dch/balanced.png"
              alt=""
              width="205"
              height="296"
            />
          </div>
          <div className="col-12 col-sm-8">
            <h3 className="txt--pink mb--30 txt--uppercase txt--sm-left h3--mob mb--xs-10">
              <strong>Balanced</strong>
            </h3>
            <ul>
              <li>
                <strong>FUEL YOUR BODY RIGHT:</strong> Get the best results combining the healthy eating guide and
                recipes in this program!
              </li>
              <li>
                <strong>HAVE FUN!</strong> Nothing is worse than the workout we dread doing! With the constant variety,
                quick format and fast pace, you’ll never be bored and you’ll be looking forward to #bettyrocking!
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section className="bg--section">
        <div className="container container--md">
          <h2 className="mb--40 txt--capitalize txt--pink h2--mob mb--xs-30">Now it's your turn...</h2>

          <div className="row">
            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-7.jpg?v=1.0.1"
                    alt="Testimonial of Angela H."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Feeling so much confidence!</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “I know I’ve said it before and I’ll say it again – doing your programs has given me so much
                      confidence. I think this is the best I have felt EVER and I will be 38 this summer!”
                    </p>
                    <p className="txt--pink">
                      <strong>- Angela H.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-8.jpg?v=1.0.1"
                    alt="Testimonial of Gina B."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Got a 6-pack!</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “Thanks Betty Rocker! <br />I tell all my friends about you!”
                    </p>
                    <p className="txt--pink">
                      <strong>- Gina B.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-9.jpg?v=1.0.1"
                    alt="Testimonial of Melissa S."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Added Muscle and Tightened Up!</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      “I’m only in my second month, but I am loving the nutrition and exercise! I’m definitely starting
                      to feel stronger and I can tell my stamina is increasing during the workouts!”
                    </p>
                    <p className="txt--pink">
                      <strong>- Melissa S.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-10.jpg"
                    alt="Testimonial of Nelly C."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Lost inches where it counted!</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>“Week 10 and I’m so happy with my progress! I can see my body changing!”</p>
                    <p className="txt--pink">
                      <strong>- Nelly C.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-11.jpg"
                    alt="Testimonial of Liz D."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Got lean and toned!</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>“I can’t believe how it just came off! I’m so thankful I found you.”</p>
                    <p className="txt--pink">
                      <strong>- Liz D.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--10 mb--xs-0">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/90dch/testimonial-12.jpg"
                    alt="Testimonial of Tiffany D."
                    width="432"
                    height="432"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">Amazing Progress!</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>“I’m so happy with how much progress I have made in just 30 days!”</p>
                    <p className="txt--pink">
                      <strong>- Tiffany D.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--dark js-section" id="included">
        <div className="container">
          <h2 className="txt--pink mb--40 mb--xs-0 h2--mob lh--xs-1_3">
            Your Complete 90-Day Challenge Program Includes…
          </h2>

          <div className="row border-bottom-1 border-gray mb--30 pb--40 pb--xs-25">
            <div className="col-12 col-lg-6 txt--center">
              <img
                loading="lazy"
                className="lazy no-shadow img--out-left"
                src="/images/90dch/screens.png"
                alt=""
                width="686"
                height="464"
              />
            </div>
            <div className="col-12 col-lg-6">
              <h3 className="txt--md-left txt--white h3--mob mb--40 mb--xs-20">
                <strong>
                  90-Day Progressive BODYWEIGHT <br className="d-none d-sm-block" /> Workout PLAN{" "}
                  <span className="txt--pink">$197 value</span>
                </strong>
              </h3>

              <ul>
                <li className="txt--white">
                  Get fit from home with these metabolic conditioning workouts that don’t require any equipment or a
                  gym.
                </li>
                <li className="txt--white">
                  Progress no matter what fitness level you’re at with demos from beginner to advanced, low to high
                  impact.
                </li>
                <li className="txt--white">
                  Burn more fat in less time with these specially formatted workouts that take 15-20 minutes.
                </li>
                <li className="txt--white">
                  Follow along videos for every workout to help you master your form and get motivated!
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 txt--center">
              <img
                loading="lazy"
                className="lazy no-shadow img--out-left mb--30"
                src="/images/90dch/meals.png"
                alt=""
                width="686"
                height="497"
              />
            </div>
            <div className="col-12 col-lg-6">
              <h3 className="txt--md-left txt--white mb--40 h3--mob mb--xs-20">
                <strong>
                  8-Week Meal Plan <span className="txt--pink">$147 value</span>
                </strong>
              </h3>

              <ul>
                <li className="txt--white">
                  Grocery lists, healthy recipes, food prep and daily menus – gluten-free, dairy-free, vegetarian
                  options included.
                </li>
                <li className="txt--white">Grocery lists and weekly food prep guides for every week.</li>
                <li className="txt--white">Daily menus and delicious meals – vegetarian options included.</li>
                <li className="txt--white">
                  Complete getting started guide shows you how to make this work easily for you.
                </li>
                <li className="txt--white">
                  Totally versatile system – use it as it’s written or use it as a guide to simplify your life.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/*bonuses*/}
      <section className="bg--section arrow-down">
        <div className="container">
          <h2 className="txt--pink mb--40 h2--mob mb--xs-20 lh--xs-1_3">
            Special Bonus Gifts To Help You Maximize Your Results
          </h2>
        </div>
        <div className="container container--md">
          <div className="card card--tag mb--30 mb--xs-20">
            <div className="card__head card__head--white">
              <div className="tag tag--md">
                <span className="d-none d-md-inline">Bonus</span> #1
              </div>
              <h4>
                <strong>
                  Body Alignment and Self-Care Series <span className="txt--pink">$79 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content txt--22">
              <div className="row align-items-center">
                <div className="col-md-6 txt--sm-center mb--sm-20">
                  <img
                    loading="lazy"
                    className="lazy no-shadow"
                    src="/images/90dch/bonus-1.png"
                    alt=""
                    width="470"
                    height="318"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>Full body stretching guide by body part to stay flexible.</li>
                    <li>
                      Core and glute activation guide to increase body awareness and help you get the MOST out of your
                      workouts.
                    </li>
                    <li>
                      Push-up progression tutorial to master the push up with options to meet you where you’re at.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag mb--30 mb--xs-20">
            <div className="card__head card__head--white">
              <div className="tag tag--md">
                <span className="d-none d-md-inline">Bonus</span> #2
              </div>
              <h4>
                <strong>
                  Workout Calendars to Keep You on Track <span className="txt--pink">$29 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content txt--22">
              <div className="row align-items-center">
                <div className="col-md-6 txt--sm-center mb--sm-20">
                  <img
                    loading="lazy"
                    className="lazy no-shadow"
                    src="/images/90dch/bonus-2.png"
                    alt=""
                    width="446"
                    height="302"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      Interactive live calendar that tracks Your progress and keeps you going through each 30-day
                      milestone.
                    </li>
                    <li>Bonus calendar with an optimized schedule for Rockstars in perimenopause or postmenopause!</li>
                    <li>
                      Printable workout calendars to use for motivation at home – hang it on your wall so you see it
                      daily!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag mb--30 mb--xs-20">
            <div className="card__head card__head--white">
              <div className="tag tag--md">
                <span className="d-none d-md-inline">Bonus</span> #3
              </div>
              <h4>
                <strong>
                  Weekly Goal Setting and Motivation Series <span className="txt--pink">$119 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content txt--22">
              <div className="row align-items-center">
                <div className="col-md-6 txt--sm-center mb--sm-20">
                  <img
                    loading="lazy"
                    className="lazy no-shadow"
                    src="/images/90dch/bonus-3.png"
                    alt=""
                    width="454"
                    height="309"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>Stay motivated weekly with this special series that will help you reach your goals.</li>
                    <li>
                      Weekly activities and mindset strategies that will make your fitness journey even more meaningful.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag mb--30 mb--xs-20">
            <div className="card__head card__head--white">
              <div className="tag tag--md">
                <span className="d-none d-md-inline">Bonus</span> #4
              </div>
              <h4>
                <strong>
                  90-Day Coaching E-Course:
                  <br className="d-none d-md-block" />
                  Customizable Start Date <span className="txt--pink">$99 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content txt--22">
              <div className="row align-items-center">
                <div className="col-md-6 txt--sm-center mb--sm-20">
                  <img
                    loading="lazy"
                    className="lazy no-shadow"
                    src="/images/90dch/bonus-4.png"
                    alt=""
                    width="505"
                    height="357"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>Start the challenge when you’re ready and receive a daily email push to stay on track!</li>
                    <li>
                      Activities include: commitment statements, check in reminders, bonus nutrition and fitness
                      content, and lots of motivation to ensure your success!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag mb--xs-0">
            <div className="card__head card__head--white">
              <div className="tag tag--md">
                <span className="d-none d-md-inline">Bonus</span> #5
              </div>
              <h4>
                <strong>
                  BONUS: The Entire 30-Day Challenge
                  <br className="d-none d-md-block" />
                  Home Fitness Video Collection <span className="txt--pink">$118 VALUE</span>
                </strong>
              </h4>
            </div>
            <div className="card__content txt--22">
              <div className="row align-items-center">
                <div className="col-md-6 txt--sm-center mb--sm-20">
                  <img
                    loading="lazy"
                    className="lazy no-shadow"
                    src="/images/90dch/bonus-5.png"
                    alt=""
                    width="470"
                    height="251"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>30 follow-along workout videos to mix and match with your program.</li>
                    <li>Easy to follow training and form notes.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*coaches*/}
      <section className="js-section" id="coaches">
        <div className="container container--md">
          <h2 className="txt--pink txt--capitalize mb--40 h2--mob mb--xs-20">Meet your coaches</h2>

          <div className="items-odd-even">
            <div className="item">
              <div className="item__image">
                <div className="d-inline-block img--round border-5 border-pink mb--15">
                  <img
                    loading="lazy"
                    className="shadow-0 img--round radius- border-8 border--md-8 border-white lazy"
                    src="/images/coaches/betty-round.jpg"
                    alt=""
                    width="433"
                    height="433"
                  />
                </div>
              </div>
              <div className="item__content">
                <h4 className="font-lato mb--20 h4--mob mb--xs-10">
                  <strong className="fw--bold">
                    Head Trainer: Bree Argetsinger <br />
                    AKA <span className="txt--pink">The Betty Rocker</span>
                  </strong>
                </h4>
                <p>
                  C.H.E.K. Certified Exercise Coach, ISSA Certified Fitness Nutrition Coach, Team Leader and Head Coach:
                  You’ll be joining me for the majority of our workouts, and I’ve invited some of the Team Betty Rocker
                  Coaches to add bonus workouts and expertise so you get the MAXIMUM value in our time together!
                </p>
              </div>
            </div>

            <div className="item">
              <div className="item__image">
                <div className="d-inline-block img--round border-5 border-pink mb--15">
                  <img
                    loading="lazy"
                    className="shadow-0 img--round radius- border-5 border-white lazy"
                    src="/images/coaches/amanda-round.png"
                    alt=""
                    width="250"
                    height="250"
                  />
                </div>
              </div>
              <div className="item__content">
                <h4 className="font-lato mb--20 h4--mob mb--xs-10">
                  <strong className="fw--bold">
                    Team Betty Rocker Coach: <span className="txt--pink">Amanda Hill</span>
                  </strong>
                </h4>
                <p>
                  NASM and NESTA Certified Personal Trainer; Crossfit Level 1 Trainer; TRX Qualified Trainer; Team Betty
                  Rocker Coach: Coach Amanda will be bringing the heat with extra abs and core workouts!
                </p>
              </div>
            </div>

            <div className="item">
              <div className="item__image">
                <div className="d-inline-block img--round border-5 border-pink mb--15">
                  <img
                    loading="lazy"
                    className="shadow-0 img--round radius- border-5 border-white lazy"
                    src="/images/coaches/nikki-round.png"
                    alt=""
                    width="250"
                    height="250"
                  />
                </div>
              </div>
              <div className="item__content">
                <h4 className="font-lato mb--20 h4--mob mb--xs-10">
                  <strong className="fw--bold">
                    Team Betty Rocker Coach: <span className="txt--pink">Nikki Cotton</span>
                  </strong>
                </h4>
                <p>
                  Yoga Instructor ERYT200; AFAA Certified Personal Trainer; Mat Pilates instructor; Team Betty Rocker
                  Coach: Enjoy weekly yoga with the fabulous Coach Nikki to get stronger, more supple, more flexible,
                  and more balanced!
                </p>
              </div>
            </div>

            <div className="item">
              <div className="item__image">
                <div className="d-inline-block img--round border-5 border-pink mb--15">
                  <img
                    loading="lazy"
                    className="shadow-0 img--round radius- border-5 border-white lazy"
                    src="/images/coaches/alyssa-round.png"
                    alt=""
                    width="250"
                    height="250"
                  />
                </div>
              </div>
              <div className="item__content">
                <h4 className="font-lato mb--20 h4--mob mb--xs-10">
                  <strong className="fw--bold">
                    Team Betty Rocker Coach: <span className="txt--pink">Alyssa Crannell</span>
                  </strong>
                </h4>
                <p>
                  NESTA, NCSF; Team Betty Rocker Coach: Coach Alyssa brings her special goal setting and motivational
                  series of workshops to you weekly to ensure your success during – and long after – this program!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--light">
        <div className="container container--xxl">
          <h2 className="fw--regular mb--5 h2--mob lh--xs-1_3">
            <strong>Click the “Start Now” Button Now</strong>{" "}
            <span className="d-md-inline-block">to Start the 90 Day Challenge…</span>
          </h2>
        </div>
        <div className="container">
          <h3 className="txt--pink mb--10 h3--mob">with over $444 Of Amazing Bonuses… 100% FREE!</h3>
          <p className="txt--gray txt--26 mb--40 txt--center txt--xs-20 mb--xs-20">Instant Access (No Waiting)</p>

          <img
            loading="lazy"
            className="lazy no-shadow"
            src="/images/90dch/90-day-promo.png"
            alt=""
            width="1184"
            height="681"
          />
        </div>
      </section>

      {/*what you get + price*/}
      <section className="bg--darkoverlay">
        <div className="container">
          <div className="border-bottom-1 border-light-gray pb--40 pb--xs-25">
            <h2 className="txt--capitalize mb--40 h2--mob mb--xs-20">What you get</h2>
            <div className="container container--xs">
              <ul>
                <li>
                  Complete <strong>90-Day Challenge Workout Video Course</strong>{" "}
                  <span className="txt--pink">($197 Value)</span>
                </li>
                <li>
                  8-Week <strong>Done for You Meal Plan</strong> with Recipes, Shopping Lists, Daily Menus and Prep
                  Guides <span className="txt--pink">($147 Value)</span>
                </li>
                <li>
                  Body Alignment and <strong>Self-Care Series</strong> <span className="txt--pink">($79 Value)</span>
                </li>
                <li>
                  90 Day Challenge <strong>Calendars</strong> <span className="txt--pink">($29 Value)</span>
                </li>
                <li>
                  90 Day Weekly <strong>Goal Setting and Motivation</strong> Series{" "}
                  <span className="txt--pink">($119 Value)</span>
                </li>
                <li>
                  90 Day <strong>Coaching and Accountability</strong> E-Course{" "}
                  <span className="txt--pink">($99 Value)</span>
                </li>
                <li>
                  The Complete <strong>30-Day Challenge</strong> Video Course{" "}
                  <span className="txt--pink">($118 Value)</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container txt--center border-top-1 border-light-gray pt--30">
          <h2 className="fw--regular h2--xs h2--xs-20 h2--xxxxs-18 mb--0 lh--xs-1_3">
            <strong>Program</strong> <span className="txt--pink">($344 value)</span> <strong>+ Bonuses </strong>{" "}
            <span className="txt--pink">($444 value)</span>{" "}
            <span className="d-inline-block">
              <strong>= $788 Value</strong>
            </span>
          </h2>

          <div className="container container--md pt--40 pt--sm-30" id="start-now">
            <DigitalProductBuyBox shopifyProduct={product} color="gray" />
          </div>
        </div>
      </section>

      {/*fb testimonials*/}
      <section className="bg--section arrow-down">
        <div className="container">
          <h2 className="txt--pink mb--40 h2--mob mb--xs-20 lh--xs-1_3">Real Success Stories, From Real People!</h2>

          <div className="row img--stack-wrapper align-items-center mb--20">
            <div className="col-md-6 mb--50 mb--xs-30">
              <div className="img mb--50 mb--xs-30 img-stack border-5 border-lighter-gray bg--white txt--center">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/90dch/fb-testimonial-5.jpg"
                  alt=""
                  width="990"
                  height="646"
                />
              </div>

              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/90dch/fb-testimonial-6.jpg"
                  alt=""
                  width="568"
                  height="172"
                />
              </div>
            </div>
            <div className="col-md-6 mb--50 mb--xs-30">
              <div className="img mb--50 mb--xs-30 img-stack border-5 border-lighter-gray bg--white txt--center">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/90dch/fb-testimonial-7.jpg"
                  alt=""
                  width="986"
                  height="524"
                />
              </div>

              <div className="img-stack">
                <img
                  loading="lazy"
                  className="lazy"
                  src="/images/90dch/fb-testimonial-8.jpg"
                  alt=""
                  width="998"
                  height="438"
                />
              </div>
            </div>
          </div>
          <p className="txt--center txt--15 lh--auto">
            <em>
              * Individual results may vary and may not be typical or representative of what you might expect.{" "}
              <br className="d-none d-lg-block" />
              To learn more about our community’s experiences with our programs look at our Testimonial Support page.
            </em>
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="border-bottom-1 border-light-gray pb--50 pb--xs-40 mb--60 mb--xs-30">
            <div className="box-guarantee">
              <img
                loading="lazy"
                src="/images/guarantee.png"
                alt=""
                width="239"
                height="245"
                className="lazy no-shadow"
              />
              <div className="row">
                <div className="col-lg-8">
                  <h2 className="h2--sm txt--white mb--20 txt--smd-left h2--mob lh--xs-1_3">
                    60 – Day Money Back GUARANTEE
                  </h2>
                  <p className="txt--white">
                    Take the first step toward improving your health by trying my 90-day challenge plan for the next 60
                    days. If you don’t feel healthier, more energized and simply amazing, you won’t pay. If you’re not
                    satisfied, simply contact customer service within 60 days for a full refund.
                  </p>

                  <p className="txt--white txt--22 fw--black">
                    <span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc
                  </p>
                </div>
              </div>
            </div>
          </div>

          <h2 className="txt--pink mb--40 h2--mob mb--xs-30">WARNING - Time Is A Factor</h2>

          <img
            loading="lazy"
            src="/images/90dch/clock.jpg"
            alt=""
            width="448"
            height="448"
            className="pull--right radius-circle shadow-3 border-10 border-white"
          />

          <p>
            Please note that this opportunity is extremely limited because of the low price I’m offering you. Remember,
            you’re getting a 90-Day home workout program with meal plans, workouts and guides, and{" "}
            <strong>saving over $170 (around 55%) off the usual price.</strong>
          </p>

          <p>This is a promotional price and will be increased without warning.</p>

          <p>
            With this program,{" "}
            <strong>I’m giving you THE ULTIMATE BLUEPRINT to creating a healthy lifestyle and body you love,</strong>{" "}
            including the workouts and the meals that have helped me personally stay consistent, get in the best shape
            of my life on a limited time budget, and feel completely in control of my life.
          </p>

          <p>
            Best of all, it will take you very little time to start seeing and feeling the results of using this
            program, because you will have the <strong>guidance</strong>, the <strong>workouts</strong> and the{" "}
            <strong>plan to follow</strong> to take action on creating a fit body and healthier lifestyle than you have
            ever experienced.
          </p>
        </div>
      </section>

      <section className="bg--light">
        <div className="container container--xxl">
          <h2 className="fw--regular mb--5 h2--mob lh--xs-1_3">
            <strong>Click the “Start Now” Button Now</strong>{" "}
            <span className="d-md-inline-block">to Start the 90 Day Challenge…</span>
          </h2>
        </div>
        <div className="container">
          <h3 className="txt--pink mb--10 h3--mob">with over $444 Of Amazing Bonuses… 100% FREE!</h3>
          <p className="txt--gray txt--26 mb--40 txt--center txt--xs-20 mb--xs-20">Instant Access (No Waiting)</p>

          <img
            loading="lazy"
            className="lazy no-shadow"
            src="/images/90dch/90-day-promo.png"
            alt=""
            width="1184"
            height="681"
          />
        </div>
      </section>

      {/*what you get + price*/}
      <section className="bg--darkoverlay">
        <div className="container">
          <div className="border-bottom-1 border-light-gray pb--40 pb--xs-25">
            <h2 className="txt--capitalize mb--40 h2--mob mb--xs-20">What you get</h2>
            <div className="container container--xs">
              <ul>
                <li>
                  Complete <strong>90-Day Challenge Workout Video Course</strong>{" "}
                  <span className="txt--pink">($197 Value)</span>
                </li>
                <li>
                  8-Week <strong>Done for You Meal Plan</strong> with Recipes, Shopping Lists, Daily Menus and Prep
                  Guides <span className="txt--pink">($147 Value)</span>
                </li>
                <li>
                  Body Alignment and <strong>Self-Care Series</strong> <span className="txt--pink">($79 Value)</span>
                </li>
                <li>
                  90 Day Challenge <strong>Calendars</strong> <span className="txt--pink">($29 Value)</span>
                </li>
                <li>
                  90 Day Weekly <strong>Goal Setting and Motivation</strong> Series{" "}
                  <span className="txt--pink">($119 Value)</span>
                </li>
                <li>
                  90 Day <strong>Coaching and Accountability</strong> E-Course{" "}
                  <span className="txt--pink">($99 Value)</span>
                </li>
                <li>
                  The Complete <strong>30-Day Challenge</strong> Video Course{" "}
                  <span className="txt--pink">($118 Value)</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container pt--30">
          <h2 className="fw--regular h2--xs h2--xs-20 h2--xxxxs-18 mb--40 mb--xs-20 lh--xs-1_3">
            <strong>Program</strong> <span className="txt--pink">($344 value)</span> <strong>+ Bonuses </strong>{" "}
            <span className="txt--pink">($444 value)</span>{" "}
            <span className="d-inline-block">
              <strong>= $788 Value</strong>
            </span>
          </h2>

          <DigitalProductBuyBox shopifyProduct={product} color="gray" order={2} />
        </div>
      </section>

      <section className="bg--section js-section py--50 pb--xs-30" id="faq">
        <div className="container">
          <h2 className="mb--40 h2--mob mb--xs-20 lh--xs-1_3">
            Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span>
          </h2>
        </div>
        <div className="container container--xs">
          <Faqs productName="90dch" />
        </div>
      </section>
    </>
  )
}
export default NinetyDayChallenge
