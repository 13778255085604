import React, { useEffect } from "react"

// Utilities
import { scrollTo } from "../utilities/scrollTo"
import testimonials from "../utilities/testimonials"
import lazyLoad from "../utilities/handleLazyLoad"

// Component
const Seo = lazyLoad(() => import("../components/Seo/Seo"))
const ScrollToTabs = lazyLoad(() => import("../components/Scrollable/ScrollToTabs"))
const DigitalProductBuyBox = lazyLoad(() => import("../components/ProductBuyBox/DigitalProductBuyBox"))
const Faqs = lazyLoad(() => import("../components/Accordions/Faqs"))
const Video = lazyLoad(() => import("../components/Video/Video"))

/**
 * @param tabs - tab content
 * @param product - shopify product data
 * */
const BodyFuelSystem = ({ tabs, product }) => {
  useEffect(() => {
    testimonials(product)
  }, [])

  return (
    <>
      {/*page title and og share*/}
      <Seo
        pageTitle="Body Fuel System - Get Lean and Stay Lean | The Betty Rocker"
        title="Body Fuel System - Get Lean and Stay Lean"
        description="This 6-week easy eating system and meal plan (gluten and dairy free) is a complete blueprint to making foods work like a team in your body so you can burn more fat, feel more energized and create a healthy lifestyle."
        url="https://store.thebettyrocker.com/products/body-fuel-system/"
        imgSrc="https://store.thebettyrocker.com/images/fb-share/bfs-fb.jpg"
      />

      {/*sales hero*/}
      <section className="hero pt--xs-30 bg--section py--xs-20">
        <div className="container txt--center">
          <h1 className="txt--pink txt--uppercase h1--lg mb--10">ROCK YOUR BODY</h1>
          <h2 className="txt--40 txt--sm-36 txt--xs-25 fw--regular mb--30 mb--xs-20">
            With my easy eating system and healthy recipes
            <br className="d-none d-md-block" /> that will sculpt you from the{" "}
            <span className="d-inline-block">inside out!</span>
          </h2>

          <div className="container container--sm">
            <div id="videoframe" className="video bg--white video--16-9 border-5 border-white mb--30 mb--xs-20">
              <Video url="https://player.vimeo.com/video/249962643" />
            </div>
          </div>

          <a className="btn btn--md mb--10 mb--xs-0 fw--medium" onClick={scrollTo} href="#start-now">
            start now
          </a>
        </div>
      </section>

      <ScrollToTabs tabs={tabs} supplements={false} />

      {/*allow your body to look as good on the outside as you feel on the inside*/}
      <section className="container arrow-down">
        <div className="row bg--bfs-betty pt--30 pt--md-0">
          <div className="col-12 col-md-9 col-lg-8 offset-lg-4">
            <h3 className="txt--pink txt--left mb--30 h3--mob mb--xs-20">
              <strong>Fueling with the right balance of nutrients will…</strong>
            </h3>

            <ul className="mb--40">
              <li>
                Give your body a 6-WEEK RESET, and improve your <strong className="fw--bold">gut health</strong>
              </li>
              <li>
                Speed up <strong className="fw--bold">muscle building and fat loss</strong> – especially when combined
                with regular exercise
              </li>
              <li>
                Reduce <strong className="fw--bold">cravings</strong>, increase your{" "}
                <strong className="fw--bold">energy</strong>, and improve your{" "}
                <strong className="fw--bold">sleep</strong>
              </li>
              <li>
                Help you LOOK and FEEL YOUR PERSONAL BEST… and stay that way - all while{" "}
                <strong className="fw--bold">eating foods that taste great!</strong>
              </li>
            </ul>

            <div className="row h--sm-250 align-items-center no-gutters">
              <div className="col-8 offset-4 col-md-12 offset-md-0">
                <h3 className="txt--center h3--mob mb--40 mr--80 mr--lg-0">
                  <strong>
                    Push the “easy button” and enjoy <span className="txt--pink">great-tasting food</span>
                    <br className="d-none d-xl-block" /> that supports{" "}
                    <span className="d-inline-block">your goals!</span>
                  </strong>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <img loading="lazy" src="/images/bfs/food.png" alt="Food Row." width="1170" height="220" />
      </section>

      {/*It’s Time To Ditch Your Diet*/}
      <section className="bg--section arrow-down">
        <div className="container">
          <h3 className="mb--40 h3--mob mb--xs-20 lh--xs-1_3">
            <strong>
              Want to see better results from your workouts?
              <br className="d-none d-lg-block" /> Upgrade the <span className="txt--pink">fuel</span> you’re taking in!
            </strong>
          </h3>

          <img
            loading="lazy"
            src="/images/bfs/bfs-promo-2-sm.png"
            alt=""
            width="320"
            height="306"
            className="d-none d-md-block pull--right lazy mb--40 ml--30i"
          />

          <p>
            How we prepare ourselves for the workout <strong>before we get there</strong> (are we rested, are we fueled)
            and how we take care of ourselves <strong>after the workout is over</strong> (do we fuel with the right
            nutrients, do we give ourselves recovery time) determines whether we build muscle and burn fat, or whether
            we break more muscle down and make it harder for the body to lose fat.
          </p>
          <p>
            Remember that a workout breaks down muscle tissue and is a kind of stress to your body. It’s not until after
            you are done that the repair and rebuild process can begin.
          </p>
          <h3 className="txt--pink txt--left mb--30 h3--mob">
            <strong>
              We can’t lose body fat and muscle can’t repair and recover without the right nutrient building blocks.
            </strong>
          </h3>
          <p className="clearfix">
            This is so important for women of all ages, but becomes absolutely imperative during{" "}
            <strong>perimenopause</strong> and <strong>menopause</strong> as we become more insulin sensitive (meaning
            high sugar foods trigger fat storage more easily) and we don’t absorb the amino acids from the protein we
            eat as easily meaning we really need to <strong>consistently</strong> be paying attention to eating a
            balanced diet that supports our training.
          </p>
          <img
            loading="lazy"
            src="/images/bfs/testimonial-1.jpg"
            alt=""
            width="400"
            height="400"
            className="d-block d-md-none pull--right lazy mb--0i"
          />
        </div>
      </section>

      {/*testimonials*/}
      <section id="results" className="arrow-down">
        <div className="container">
          <h3 className="mb--60 h3--mob mb--xs-30 lh--xs-1_3">
            <strong>
              See what pushing the “easy button” with the
              <br className="d-none d-lg-block" /> <span className="txt--pink">Body Fuel System</span> did for people
              just <span className="d-inline-block">like you…</span>
            </strong>
          </h3>
        </div>
        <div className="container container--md">
          <div className="row">
            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-2.jpg?v=1.0.1"
                    alt="Testimonial of Alicia A."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">
                      "For the first time in my life, I can honestly say I'm happy with my body."
                    </h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>This inspiration you’ve given me has led me to lose 24 1/2 inches and 20 pounds.</p>
                    <p>For the first time in my life (I’m 23) I can honestly say I’m happy with my body.</p>
                    <p className="txt--pink">
                      <strong>- Alicia A.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-4.jpg?v=1.0.1"
                    alt="Testimonial of Katlyn C."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Sorry I'm not sorry for Betty Rocking!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I started the Body Fuel System right after Thanksgiving to guide me through the end of the
                      semester and finals.
                    </p>
                    <p>
                      When I was done, I used the recipes and principles to guide my meals through my days of work and
                      working out....and oh yeah, endless holiday food!
                    </p>
                    <p className="txt--pink">
                      <strong>- Katlyn C.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-5.jpg?v=1.0.1"
                    alt="Testimonial of Lou H."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">
                      "5 weeks in and he has lost 22lbs and I have lost 13lbs and so stoked!!"
                    </h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>We decided to make changes in our lives and my hubby found you on the internet.</p>
                    <p>We immediately bought the Body Fuel System and got started right away.</p>
                    <p>5 weeks in and he has lost 22lbs and I have lost 13lbs and so stoked!</p>
                    <p>Proud in so many ways so again, a big thank you for your motivation and dedication.</p>
                    <p>Betty....you rocked our LIVES!</p>
                    <p className="txt--pink">
                      <strong>- Lou H.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-6.jpg?v=1.0.1"
                    alt="Testimonial of Jenna T."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">
                      "The Body Fuel System was everything I needed to get over my weight plateau."
                    </h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>I’ve always hated cooking with a passion!</p>
                    <p>Not to mention never had any good ideas for meals.</p>
                    <p>So when it came to wanting to cut fat and tone up I was lost on where to start</p>
                    <p>The Body Fuel System was the perfect solution for me!</p>
                    <p>Not only was this plan easy to follow but the meals were delicious!</p>
                    <p>Everything tasted amazing!!</p>
                    <p>It was exactly what I needed to get over my weight plateau.</p>
                    <p>
                      If you are looking for some new and healthy ideas for meals I highly suggest the Body Fuel System!
                    </p>
                    <p className="txt--pink">
                      <strong>- Jenna T.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="txt--center txt--15 lh--auto">
            <em>
              * Individual results may vary and may not be typical or representative of what you might expect.{" "}
              <br className="d-none d-lg-block" />
              To learn more about our community’s experiences with our programs look at our Testimonial Support page.
            </em>
          </p>
        </div>
      </section>

      <section className="bg--section">
        <div className="container">
          <h3 className="h3--mob mb--40 fw--medium mb--xs-30 lh--xs-1_3">
            <strong>
              If you’ve been struggling to see results for a while
              <br className="d-none d-lg-block" /> you’ve probably had thoughts{" "}
              <span className="d-inline-block">like this…</span>
            </strong>
          </h3>

          <div className="row">
            <div className="col-12 col-md-5 txt--center">
              <img
                loading="lazy"
                src="/images/bfs/woman-before-fridge2.jpg"
                alt=""
                width="470"
                height="300"
                className="lazy shadow-3 mb--30"
              />
            </div>
            <div className="col-12 col-md-7">
              <ul>
                <li>If I could just control my cravings I can tighten everything up…</li>
                <li>I just need to force myself to exercise more every day….</li>
                <li>I probably need to eat less or count my calories more carefully…</li>
                <li>
                  I shouldn’t eat bread or drink wine because low carb and low fat is the only way I can get lean…
                </li>
                <li>Other people have an easier time than me because of their genetics…</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="container container--md txt--center">
        <h3 className="h3--mob mb--40 lh--xs-1_3 mb--xs-20">
          <strong>
            Now I can’t read your mind, but I know that you’re <br className="d-none d-lg-block" /> probably nodding
            your head to at least a few of those.
          </strong>
        </h3>
        <p className="mb--40 txt--center">
          The thing is, I’ve seen so many women get stuck in a vicious{" "}
          <strong className="fw--bold">
            <span className="txt--pink">“Tri-et”cycle</span>
          </strong>{" "}
          (that’s where you “try every diet”) and burn themselves out overtraining. By the time they make it to
          perimenopause or menopause, where their hormones are already changing, they have few reserves to fall back on
          as they’ve been depleting their energy stores and missing pieces of the puzzle when it comes to how to support
          their valuable muscle tissue and metabolic health.
        </p>

        <img
          loading="lazy"
          src="/images/bfs/triet-circle.png"
          alt=""
          width="658"
          height="431"
          className="lazy no-shadow mb--20"
        />
      </section>

      <section className="container pt--0">
        <h3 className="mb--40 h3--mob mb--xs-30">
          <strong>
            It’s time to break the cycle and start to nourish yourself in a
            <br className="d-none d-lg-block" /> sustainable way. It’s time to make the fuel you put in the tank serve
            <br className="d-none d-lg-block" /> your goals and support your energy{" "}
            <span className="d-inline-block">and workouts.</span>
          </strong>
        </h3>

        <div className="container container--sm">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="testimonial__img img-w-xs mx--auto">
                <img
                  loading="lazy"
                  src="/images/bfs/testimonial-sm.jpg"
                  alt=""
                  width="340"
                  height="340"
                  className="lazy no-shadow"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <p>
                I was stuck in that cycle myself in my teens and 20’s. It took education, training, and experimentation
                to develop a sustainable way of life that worked.
              </p>
              <p>
                After testing it on myself for over a decade, I began sharing it with my clients and refining it to a
                science. Seeing people go from frustrated and depleted to energized, strong and flourishing has been the
                greatest reward!
              </p>

              <img
                loading="lazy"
                src="/images/bfs/arrow-round.png"
                alt=""
                width="153"
                height="203"
                className="lazy arrow-img"
              />
            </div>
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section className="bg--section pt--70i">
        <div className="container container--md">
          <div className="row">
            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-7.jpg?v=1.0.1"
                    alt="Testimonial of Dalida I."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">
                      "I am happy with what I put in my mouth because it shows in my body!"
                    </h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I just wanted to email you to thank you again – I’ve been eating clean and training well and not
                      depriving myself, and I legit see my (six) packs coming in!
                    </p>
                    <p>And some of my jeans fit!</p>
                    <p>I have a lot to thank you for.</p>
                    <p>
                      I make your shakes and your other dishes, and I am happy with what I put in my mouth because it
                      shows in my body.
                    </p>
                    <p className="txt--pink">
                      <strong>- Dalida I.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-9.jpg"
                    alt="Testimonial of Rocio C."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"Love my new curves!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>Before and after 4 weeks of the Betty Rocker’s Body Fuel System!</p>
                    <p>My husband did it too!</p>
                    <p className="txt--pink">
                      <strong>- Rocio C.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-11.jpg?v=1.0.1"
                    alt="Testimonial of Jasmine F."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"The green smoothies in this program have changed my life!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I had a preconceived notion that if I wanted to lose weight, my food needed to be plain and
                      boring, because if not I was consuming too many calories.
                    </p>
                    <p>
                      Working with the Body Fuel System taught me that calories aren't the most important thing,
                      instead, my focus should be on getting the proper nutrients that my body needs.
                    </p>
                    <p>I had more energy and push harder in my vigorous workouts.</p>
                    <p>
                      The green smoothies in the program have made it possible for me to have clean and healthy "fast
                      food" on the go.
                    </p>
                    <p>They have changed my life!</p>
                    <p className="txt--pink">
                      <strong>- Jasmine F.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-12.jpg"
                    alt="Testimonial of Roslyn R."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I couldn't bust the plateau before."</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      I work out quite a lot, but it wasn’t until I did Betty Rocker’s Body Fuel System that I had
                      changes in my middle area.
                    </p>
                    <p>Seven months post-baby, and happy with this!</p>
                    <p className="txt--pink">
                      <strong>- Roslyn R.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="txt--center txt--15 lh--auto">
            <em>
              * Individual results may vary and may not be typical or representative of what you might expect.{" "}
              <br className="d-none d-lg-block" />
              To learn more about our community’s experiences with our programs look at our Testimonial Support page.
            </em>
          </p>
        </div>
      </section>

      {/*Life is BUSY and you need flexible options to make*/}
      <section className="arrow-down border-top-10 border-pink">
        <div className="container container--md">
          <h3 className="h3--mob mb--40 mb--sm-30 lh--xs-1_3 mb--xs-20">
            <strong>
              Life is BUSY and you need flexible options to make
              <br className="d-none d-lg-block" /> healthy eating SUSTAINABLE.
            </strong>
          </h3>
          <p>
            When you combine a balance of the right nutrients on your plate with an easy way to repeat this process day
            after day, you will see your body quickly respond.
          </p>
          <p>
            That’s why the meals in the Body Fuel System have all the right nutrients your body needs, and the system
            itself is flexible and gives you 3 different eating style options for making it happen each week:{" "}
            <strong className="fw--bold">Minimal, Hybrid</strong> and <strong className="fw--bold">Batch.</strong>
          </p>
          <p className="mb--40">
            You can jump between these 3 styles and use which ever one is best for you on any given week.
          </p>
        </div>

        <div className="container">
          <div className="row gutters-responsive">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0 mb--15">
              <div className="card card--padding card--height bg--minimal">
                <h3 className="txt--uppercase txt--white txt--left mb--20 h3--mob mb--xs-10">
                  <strong>Minimal</strong>
                </h3>
                <p className="txt--white">
                  You need <strong>SHORTCUTS</strong> to save you time and EATING MADE EASY that gets results - even if
                  you are super busy, don’t cook much or are traveling.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0 mb--15">
              <div className="card card--padding card--height bg--black">
                <h3 className="txt--uppercase txt--white txt--left mb--20 h3--mob mb--xs-10">
                  <strong>Hybrid</strong>
                </h3>
                <p className="txt--white">
                  You need a <strong>FLEXIBLE</strong>, easy-to-use guide that helps you stay healthy and get results on
                  a busy schedule. You can do some light prep, but have the flexibility to take the healthy shortcuts in
                  the guide.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0 mb--15 mb--xs-0">
              <div className="card card--padding card--height bg--batch">
                <h3 className="txt--uppercase txt--white txt--left mb--20 h3--mob mb--xs-10">
                  <strong>Batch</strong>
                </h3>
                <p className="txt--white">
                  You need an <strong>EFFICIENT</strong>, simple map laid out to follow so you can conveniently prep
                  your food for the week in one main session and save time all week.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--section">
        <div className="container">
          <h3 className="h3--mob mb--20 lh--xs-1_3">
            <strong>
              The Body Fuel System™ makes it easy to stay <span className="d-inline-block">on track!</span>
            </strong>
          </h3>
          <h4 className="font-lato txt--center">
            It also gives you easy, healthy, recipes (gluten and dairy free,
            <br className="d-none d-lg-block" /> Vegetarian options too) that taste great so you can support your goals
            <br className="d-none d-lg-block" /> and feel your best every day…
          </h4>
        </div>
      </section>

      <section className="bg--pink arrow-down arrow-down--pink py--sm-30 py--40">
        <h3 className="h3--mob txt--white">
          <strong>Recipes Like…</strong>
        </h3>
      </section>

      <section className="bg--section arrow-down">
        <div className="container pt--30 pt--xs-0">
          <div className="row gutters-responsive">
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-1.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    5-minute Protein Pancakes <span className="fw--regular">(Week 1)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-2.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Tofu Taco Lettuce Cups <span className="fw--regular">(Week 1)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-3.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Sauteed Shrimp <span className="d-block fw--regular">(Week 2)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-4.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Asian Lettuce Wraps <span className="fw--regular">(Week 2)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-5.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Breakfast Egg Muffins <span className="fw--regular">(Week 3)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-6.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Chicken Salad in a Jar <span className="fw--regular">(Week 3)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-7.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Captain America Green Smoothie <span className="fw--regular">(Week 4)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-8.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Pesto Shrimp Bowl <span className="d-inline-block fw--regular">(Week 4)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-9.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Ginger Pineapple Green Smoothie <span className="fw--regular">(Week 5)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-10.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Turkey Burgers <span className="d-block fw--regular">(Week 5)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15 mb--xs-0">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-11.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Blueberry Protein Muffins <span className="fw--regular">(Week 6)</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3 mb--30 mb--md-15 mb--xs-0">
              <div className="img-card">
                <img
                  loading="lazy"
                  className="shadow-0 lazy"
                  src="/images/bfs/recipe-12.jpg"
                  alt=""
                  width="270"
                  height="270"
                />
                <div className="img-card__title img-card__title--lg">
                  <span>
                    Sweet Potato Breakfast Bowl <span className="fw--regular">(Week 6)</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 txt--center">
            <img
              loading="lazy"
              src="/images/bfs/week-3-plan.png"
              alt=""
              width="570"
              height="534"
              className="lazy no-shadow mb--20"
            />
          </div>
          <div className="col-12 col-md-6">
            <h3 className="h3--mob txt--left txt--pink mb--40 mb--xs-20 lh--xs-1_3">
              <strong>Body Fuel System™ Eating Guide</strong>
            </h3>
            <p>
              This is your essential and easy to read guide to nutrition. You’ll learn how to navigate eating in all
              situations, and become a fit, healthy, confident eater!
            </p>
            <p>
              <strong>This guide will empower you to make healthy eating your LIFESTYLE, once and for all!</strong>
            </p>
          </div>
        </div>
      </section>

      <section className="bg--pink arrow-down arrow-down--pink py--sm-30 py--40">
        <h3 className="h3--mob txt--white">
          <strong>You'll Learn…</strong>
        </h3>
      </section>

      <section className="container container--md arrow-down">
        <div className="row">
          <div className="col-12 col-md-6">
            <ul>
              <li>How to CUSTOMIZE the system for your lifestyle</li>
              <li>The BEST foods for better GUT HEALTH</li>
              <li>What’s behind common belly inflammation and bloating that leads to HIDDEN weight gain</li>
              <li>How to tweak your eating when you’re in MENOPAUSE</li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <ul>
              <li>How to STAY ON TRACK in a variety of tricky eating situations</li>
              <li>How to navigate (and beat) food and sugar CRAVINGS</li>
              <li>How to SAVE MONEY at the grocery store</li>
              <li>
                How to RESET your system so you can enjoy clearer skin, better energy and digestion, and more efficient
                fat burning!
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bg--section pb--0">
        <div className="container txt--center">
          <h3 className="h3--mob mb--40 mb--xs-20 lh--xs-1_3">
            <strong>Your Body Fuel System Meal Plans</strong>
          </h3>
          <img
            loading="lazy"
            src="/images/bfs/book-meals.png"
            alt=""
            width="1260"
            height="309"
            className="lazy no-shadow"
          />
        </div>
      </section>

      {/*what is included*/}
      <section className="bg--section arrow-down js-section" id="included">
        <div className="container container--md">
          <h3 className="h3--mob mb--40 mb--xs-30">
            <strong>Here’s Whats Included:</strong>
          </h3>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--pink tag--sm">#1</div>
              <h4>
                <strong>Done-For You Weekly Menus</strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/bfs/included-3.png"
                    alt=""
                    width="422"
                    height="383"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <strong className="fw--bold">
                        You’ll get 6 Weeks of Done-For You Daily Menus so you have an ACTION PLAN for each day of the
                        week.
                      </strong>{" "}
                      This guide can help you make good choices even when you travel or eat out if you stick close to
                      the suggested guidelines.
                    </li>
                    <li>
                      <strong className="fw--bold">Customize it to YOUR schedule,</strong> it has room to take days off
                      or accommodate the unexpected. Take ALL the confusion and frustration out of wondering what to eat
                      and when so you can spend more time enjoying your life!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--30 mb--xs-20">
            <div className="card__head card__head--gray">
              <div className="tag tag--pink tag--sm">#2</div>
              <h4>
                <strong>Easy, Healthy Recipes</strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 txt--center mb--sm-30">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/bfs/included-4.png"
                    alt=""
                    width="485"
                    height="479"
                  />
                </div>
                <div className="col-md-6">
                  <p>You’ll get 6 weeks of Delicious, Easy to Prepare Fat-Burning Recipes that are:</p>

                  <ul>
                    <li>
                      <strong className="fw--bold">Gluten and Dairy Free</strong>
                    </li>
                    <li>
                      <strong className="fw--bold">Gut Friendly and High in Fiber</strong>
                    </li>
                    <li>
                      <strong className="fw--bold">Nutrient Diverse and Balanced</strong>
                    </li>
                    <li>
                      <strong className="fw--bold">Protein Forward for Active Women</strong>
                    </li>
                    <li>
                      <strong className="fw--bold">Low Glycemic Index</strong>
                    </li>
                    <li>
                      <strong className="fw--bold">Omnivore and Vegetarian Options</strong>
                    </li>
                  </ul>

                  <p>Included follow-along recipes AND complete Weekly Food Prep Guide for speed and efficiency.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--tag-sm mb--xs-0">
            <div className="card__head card__head--gray">
              <div className="tag tag--pink tag--sm">#3</div>
              <h4>
                <strong>3 Eating Style Guides</strong> (minimal, hybrid and batch) <strong>for each week</strong>
              </h4>
            </div>
            <div className="card__content">
              <div className="row align-items-center">
                <div className="col-md-6 mb--sm-30 txt--center">
                  <img
                    loading="lazy"
                    className="no-shadow lazy"
                    src="/images/bfs/included-1.png"
                    alt=""
                    width="414"
                    height="395"
                  />
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <strong className="fw--bold">Eating healthy is made easier</strong> with 3 customized options to
                      suit your preference.
                    </li>
                    <li>
                      <strong className="fw--bold">Minimal</strong> for busy times, with lots of shortcuts built in
                    </li>
                    <li>
                      <strong className="fw--bold">Hybrid</strong> for cooking on the fly and doing a little prep in
                      advance
                    </li>
                    <li>
                      <strong className="fw--bold">Batch</strong> for organization, with more advanced prep to save time
                      later
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container p--relative pb--0">
        <h3 className="h3--mob mb--40 mb--xs-20 lh--xs-1_3">
          <strong>
            WARNING: <span className="txt--pink">THIS System IS NOT for Everyone!</span>
          </strong>
        </h3>

        <div className="row">
          <div className="col-12 col-lg-11 mb--30">
            <h3 className="txt--left mb--20">Who is this for?</h3>
            <p>Well first, let me tell you who this ISN’T for.</p>
            <p>
              If you’re trying to “bulk up” this isn’t the plan for you. This also isn’t the plan for you if you’re
              trying to lose 20-30 lbs in a week. People who use this system have easily lost that amount (and more),
              but they don’t do it overnight, or in a week.
            </p>

            <h3 className="txt--left mb--20">Will this program work for you?</h3>
            <p>
              You tell me, will you work for IT? Are you willing to eat real foods, prepare simple meals, either one at
              a time or in batches? Are you willing to read a few chapters about Nutrition so you can give your body the
              actual sustenance it craves to do its functions?
            </p>
            <p>
              <strong>Because if you can do these things, this will 1000% work for you.</strong>
            </p>
          </div>
          <div className="col-12 col-lg-7">
            <h3 className="txt--left mb--20">How quickly will you see RESULTS? </h3>
            <p>
              Many of my readers send me a letter during their first week telling me they’re sleeping better, their
              bellies are shrinking, they have lost their cravings and so many more wonderful effects.
            </p>
            <p>
              But the biggest reason this works is because there is NO ONE SIZE FITS ALL approach to YOUR unique body.
              This is a blueprint to understanding the language your body speaks so you can move the needle in the
              direction you want it to go. It’s on its own schedule. Following this program will create change – it’s up
              to you how fast that happens.
            </p>

            <h3 className="txt--left mb--20">This is about creating a LIFESTYLE</h3>
            <p className="mb--50 mb--xs-0">
              A lifestyle built on harnessing the abundance of nutrients that CAN and WILL go to work for you to keep
              your body a lean, mean machine all day long, every time you eat them.
            </p>
          </div>
          <div className="col-12 col-lg-5 txt--center">
            <img
              loading="lazy"
              src="/images/bfs/betty-back.png"
              alt=""
              width="580"
              height="614"
              className="lazy no-shadow img--bottom-abs img--down"
            />
          </div>
        </div>
      </section>

      {/*testimonials*/}
      <section className="bg--section arrow-down">
        <div className="container container--md">
          <h3 className="h3--mob mb--40 mb--xs-30 lh--xs-1_3">
            <strong>
              Take a Look at Some of These <span className="txt--pink">Inspiring Messages</span> From my Thriving Fitfam
              Community That You Will be a Part of…
            </strong>
          </h3>

          <div className="row">
            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-13.jpg?v=1.0.1"
                    alt="Testimonial of Rebecca C."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I lost 6lbs in the first week!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>I needed to let you know that you have changed my life.</p>
                    <p>
                      I never thought that a “plan” would be something I would stick to or want to stick to because they
                      are all so BORING.
                    </p>
                    <p>However, yours rocks.</p>
                    <p>My way of thinking about food now is to fuel my body and not to starve it.</p>
                    <p>
                      Your recipes are great and have helped make me and my husband be healthier. I lost 6 lbs in the
                      first week!
                    </p>
                    <p className="txt--pink">
                      <strong>- Rebecca C.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-17.jpg?v=1.0.1"
                    alt="Testimonial of Veronica M."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"A new, healthier me!"</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>Still a work in progress, but I’ve been following the Body Fuel System and the workouts.</p>
                    <p>Thank you for your help, to a new healthier me.</p>
                    <p>The next phase is all up to me. I got this!!</p>
                    <p className="txt--pink">
                      <strong>- Veronica M.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-15.jpg?v=1.0.1"
                    alt="Testimonial of Ashley G."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I stopped craving sugar all the time."</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>
                      The main difference I noticed immediately after starting the Body Fuel System was that I stopped
                      craving sugar all the time.
                    </p>
                    <p>
                      I still would feel a craving every now and then (especially during emotional moments) but because
                      my body was finally adequately fueled with complex carbs and proteins and veggies on a regular
                      basis I didn't feel that desperation for sugar like I used to.
                    </p>
                    <p>
                      I read the Body Fuel System from cover to cover and what I appreciated most was not just the
                      incredible amount of information and advice, but the positive message of self-love and acceptance
                      that Betty Rocker was spreading.
                    </p>
                    <p className="txt--pink">
                      <strong>- Ashley G.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 mb--30 mb--xs-20">
              <div className="testimonial testimonial--shadow">
                <div className="testimonial__img">
                  <img
                    loading="lazy"
                    className="lazy"
                    src="/images/bfs/testimonial-16.jpg?v=1.0.1"
                    alt="Testimonial of Tekoah C."
                    width="400"
                    height="400"
                  />
                </div>
                <div className="testimonial__content">
                  <div className="testimonial__header js-testimonial-toggle">
                    <h3 className="testimonial__title">"I overcame my wine craving."</h3>
                  </div>
                  <div className="testimonial__toggeable">
                    <p>Week 4 of the Body Fuel System and I feel AMAZING!</p>
                    <p>I definitely recommend the Body Fuel System, my body feels alive!</p>
                    <p>Before I started I would have a glass of wine at night not thinking it was a big deal</p>
                    <p>I worked out, I ate healthy, I CRAVED wine at night.</p>
                    <p>But now, doing the BFS I realized I wasn't craving, I was lacking.</p>
                    <p>
                      Now I have lost that crave for wine at night and I can enjoy it when I choose to, and that's a big
                      deal for me.
                    </p>
                    <p>Thank you Betty Rocker!</p>
                    <p className="txt--pink">
                      <strong>- Tekoah C.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="txt--center txt--15 lh--auto">
            <em>
              * Individual results may vary and may not be typical or representative of what you might expect.{" "}
              <br className="d-none d-lg-block" />
              To learn more about our community’s experiences with our programs look at our Testimonial Support page.
            </em>
          </p>
        </div>
      </section>

      {/*bonuses*/}
      <section className="container arrow-down">
        <h3 className="h3--mob mb--40 mb--xs-30 lh--xs-1_3">
          <strong>
            <span className="txt--pink">SPECIAL BONUS GIFTS</span> <br /> When You Start Today!
          </strong>
        </h3>

        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
            <div className="card card--height card--border">
              <div className="tag tag--pink tag--xs">#1</div>
              <div className="card__content">
                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                  <img
                    loading="lazy"
                    src="/images/bfs/bonus-1.png"
                    alt=""
                    width="199"
                    height="236"
                    className="lazy no-shadow mb--20"
                  />
                </div>
                <h4 className="fw--medium mb--20 mb--xs-10">Body Fuel System Cookbook and Recipes Guide</h4>
                <p>All the delicious Body Fuel System recipes at your fingertips in this amazing cookbook!</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
            <div className="card card--height card--border">
              <div className="tag tag--pink tag--xs">#2</div>
              <div className="card__content">
                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                  <img
                    loading="lazy"
                    src="/images/bfs/bonus-2.png"
                    alt=""
                    width="274"
                    height="262"
                    className="lazy no-shadow mb--20"
                  />
                </div>
                <h4 className="fw--medium mb--20 mb--xs-10">6-Week E-coaching Course</h4>
                <p>
                  This companion coaching course, delivered via email, will help you start the program with ease, and
                  navigate it successfully.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
            <div className="card card--height card--border">
              <div className="tag tag--pink tag--xs">#3</div>
              <div className="card__content">
                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                  <img
                    loading="lazy"
                    src="/images/bfs/bonus-cheatsheet.png"
                    alt=""
                    width="194"
                    height="240"
                    className="lazy no-shadow mb--20"
                  />
                </div>
                <h4 className="fw--medium mb--20 mb--xs-10">Protein Cheat Sheet</h4>
                <p>
                  This handy guide shares all the nutrition information in the recipes in your plan, and shares tips for
                  protein intake at different life stages (including peri and postmenopause).
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
            <div className="card card--height card--border">
              <div className="tag tag--pink tag--xs">#4</div>
              <div className="card__content">
                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                  <img
                    loading="lazy"
                    src="/images/bfs/bonus-6.png"
                    alt=""
                    width="192"
                    height="232"
                    className="lazy no-shadow mb--20"
                  />
                </div>
                <h4 className="fw--medium mb--20 mb--xs-10">Food Swaps and Substitutions Guide</h4>
                <p>
                  Need to swap a food out for something else, or get ideas to customize your recipes? This handy guide
                  will help you!
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30">
            <div className="card card--height card--border">
              <div className="tag tag--pink tag--xs">#5</div>
              <div className="card__content">
                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                  <img
                    loading="lazy"
                    src="/images/bfs/bonus-3.png"
                    alt=""
                    width="200"
                    height="234"
                    className="lazy no-shadow mb--20"
                  />
                </div>
                <h4 className="fw--medium mb--20 mb--xs-10">Healthy Travel and Eating Out Survival Guide</h4>
                <p>A handy reference with strategies for successfully navigating traveling and dining out.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb--sm-20 mb--30 mb--xs-0">
            <div className="card card--height card--border">
              <div className="tag tag--pink tag--xs">#6</div>
              <div className="card__content">
                <div className="border-bottom-1 border-light-gray txt--center mb--20 h--260">
                  <img
                    loading="lazy"
                    src="/images/bfs/bonus-4.png"
                    alt=""
                    width="200"
                    height="238"
                    className="lazy no-shadow mb--20"
                  />
                </div>
                <h4 className="fw--medium mb--20 mb--xs-10">Eat This Not That Guide</h4>
                <p>Avoid foods that seem healthy but actually aren’t with this handy quick reference guide.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p--0 p--relative overflow--hidden">
        <div className="bg--section py--50">
          <div className="container">
            <h3 className="h3--mob mb--40 mb--xs-30 lh--xs-1_3">
              <strong>You deserve to feel your best</strong>
            </h3>

            <div className="row">
              <div className="col-12 col-lg-7">
                <p>
                  You deserve to know the REAL DEAL when it comes to the food you put in your mouth – in your KIDS’
                  mouths – in your family’s mouths, every day.
                </p>
                <p>
                  Everything I use PERSONALLY and teach my clients is inside this program, and I want you to have it.
                </p>
                <p>
                  <strong>
                    How your body looks and feels is a byproduct of how you treat yourself. If you don’t have the best
                    information, how can you make the best choices?
                  </strong>
                </p>
                <p>
                  I am a very direct person, so I’ll be cutting through all the junk to tell you EXACTLY what you need
                  to know about how to combine the foods that trigger your body’s own natural fat loss system, shore up
                  your energy, bust cravings, WHEN to eat the right foods, how to be super efficient with planning and
                  prepping – everything you need to EASILY upgrade your lifestyle to the one you deserve – FOR GOOD.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg--pink arrow-down arrow-down--pink txt--white py--30">
          <div className="container">
            <h2 className="h2--xs oversection-title mb--10 txt--left txt--white">Now It’s Time To Take Control.</h2>
            <div className="row">
              <div className="col-8 col-sm-9 col-md-10 col-lg-8">
                <h3 className="txt--left txt--white">
                  <strong>Join me, and the amazing community of people who are </strong>
                  <span className="d-inline d-xl-block mt--10">
                    Getting Fit, Feeling Great, Looking Great, and Loving Life!
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <img
          loading="lazy"
          src="/images/bfs/betty-full.png"
          alt=""
          width="386"
          height="721"
          className="lazy no-shadow img--oversection"
        />
      </section>

      <section className="container" id="start-now">
        <h3 className="h3--mob mb--10">
          <strong>Here’s How to Get Started!</strong>
        </h3>

        <img
          loading="lazy"
          src="/images/bfs/bfs-full.png"
          alt=""
          width="1300"
          height="664"
          className="lazy no-shadow mb--40 mb--xs-20"
        />

        <div className="container container--md txt--center">
          <h3 className="mb--30 h3--mob mb--xs-20">
            <strong>
              So I added up the cost of all of the components of the Body Fuel System, which came to a total of $297
              including the bonuses.
            </strong>
          </h3>

          <p>
            I didn’t write this program so it could sit on a shelf collecting dust. I wrote it so busy people like YOU
            could quickly and easily get their hands on the EXACT information you need to take control of your life and
            start actually enjoying food, loving your body and rocking some serious fitness all over your life!
          </p>
          <p className="mb--40 mb--xs-30">
            So <strong>I’ve knocked the price down over 70% off its total value</strong> to empower you to spend your
            money on what we need to make this work – the FOOD! Invest this small amount today in the Body Fuel System
            and arm yourself with the most valuable part of making this work, the knowledge to eat right for your LIFE!
          </p>

          <div className="container container--xs border-top-1 border-light-gray pt--30">
            <DigitalProductBuyBox shopifyProduct={product} singleBox={true} color="light-gray" />
          </div>
        </div>

        {/*guarantee*/}
        <div className="container pt--30">
          <div className="box-guarantee">
            <img
              loading="lazy"
              src="/images/guarantee.png"
              alt=""
              width="239"
              height="245"
              className="lazy no-shadow"
            />
            <div className="row">
              <div className="col-lg-8">
                <h3 className="h3--mob txt--white mb--20 txt--smd-left">
                  <strong>100% Money Back GUARANTEE</strong>
                </h3>

                <p className="txt--white lh--auto">
                  I know that this information and this System will have an incredibly positive impact on your life,
                  because not only do I live it, I see it, and I read about it from the people who use it over and over.
                </p>
                <p className="txt--white lh--auto">
                  But I know you have already been on a journey getting here, and I trust that you know what’s best for
                  yourself. At the end of the day, if this isn’t the right fit for you, or doesn’t create change in your
                  life, don’t sweat it. You can return it within 60 days and get your money back, no questions asked.
                </p>

                <p className="txt--white txt--22 fw--black">
                  <span className="d-block mb--10">Bree Argetsinger</span> CEO The Betty Rocker, Inc
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg--section js-section py--50 pb--xs-30" id="faq">
        <div className="container">
          <h3 className="mb--40 h3--mob mb--xs-20 lh--xs-1_3">
            <strong>
              Got Questions? <span className="d-block d-sm-inline">I’ve Got Answers...</span>
            </strong>
          </h3>
        </div>
        <div className="container container--xs">
          <Faqs productName="bfs" />
        </div>
      </section>
    </>
  )
}
export default BodyFuelSystem
